.user-tab {
  display: flex;
  align-items: center;
  background-color: #f8f8f8;
  padding: 0.5rem 0.625rem;
  /* Converted from 5px 10px */
  border-radius: 0.3125rem;
  /* Converted from 5px */
  position: absolute;
  /* Changed from absolute to fixed for consistent positioning */
  top: 3rem;
  /* Converted from 40px */
  right: 3rem;
  /* Converted from 40px */
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.1);
  /* Converted from 0 2px 4px */
  z-index: 1000;
  /* Ensures the UserTab is above other elements */
  cursor: pointer;
  /* Changes cursor to pointer */
  transition: background-color 0.3s ease;
  /* Smooth transition for background color change */
}

/* Apply hover effect only on devices that support hover */
@media (hover: hover) {
  .user-tab:hover {
    background-color: #b1b1b1;
    /* Changes background color on hover */
  }
}

.notification-icon {
  padding: 0.3125rem;
  /* Converted from 5px */
  border-radius: 50%;
  position: relative;
  margin-right: 0.625rem;
  /* Converted from 10px */
  display: flex;
  align-items: center;
}

.notification-icon :hover  {
  background-color: red; /* Fondo más claro */
  cursor: pointer; /* Cambia el cursor a "puntero" */
  border-radius: 4px; /* Opcional: redondea los bordes */
}

.notification-icon svg {
  width: 1.25rem;
  /* Converted from 20px */
  height: 1.25rem;
  /* Converted from 20px */
}

.notification-count {
  position: absolute;
  top: -0.3125rem;
  /* Converted from -5px */
  right: -0.3125rem;
  /* Converted from -5px */
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.125rem 0.3125rem;
  /* Converted from 2px 5px */
  font-size: 0.75rem;
  /* Converted from 12px */
}

.user-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 0.625rem;
  /* Converted from 10px */
}

.user-name {
  font-weight: bold;
}

.user-role {
  font-size: 0.75rem;
  /* Converted from 12px */
  color: gray;
}

/* Mobile Styles */
@media (max-width: 576px) {
  .user-tab {
    top: 2rem;
    /* Reduced top position */
    right: 1.9rem;
    /* Reduced right position */
    padding: .1rem;
    /* Adjusted padding for touch targets */
    flex-direction: row;
    /* Ensure elements are in a row */
  }

  .notification-icon {
    margin-right: 0.5rem;
    /* Adjusted margin */
    padding: 0.25rem;
    /* Adjusted padding */
  }

  .notification-icon svg {
    width: 1rem;
    /* Reduced size */
    height: 1rem;
  }

  .notification-count {
    top: -0.25rem;
    /* Adjusted position */
    right: -0.25rem;
    font-size: 0.625rem;
    /* Reduced font size */
    padding: 0.0625rem 0.25rem;
    /* Adjusted padding */
  }

  .user-info {
    margin-left: 0.5rem;
    /* Adjusted margin */
  }

  .user-name {
    font-size: 0.875rem;
    /* Adjusted font size */
  }

  .user-role {
    display: none;
    /* Hide user role to save space */
  }
}

