.keyboard-table-container {
  width: 100%;
  max-height: 67vh;
  /* El contenedor ocupará el 67% del alto de la pantalla */
  /* Adjust height to make space for header, footer, and other elements */
  overflow: auto;
  /* Allow both vertical and horizontal scrolling inside the table container */
  padding-top: 0px;
  box-sizing: border-box;
}

.keyboard-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.keyboard-table th,
.keyboard-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 150px;
  table-layout: auto;
  /* Permite que las columnas se ajusten al contenido */
  padding: 8px 10px;
  /* Reduce el padding dentro de las celdas */
  margin: 0;
  /* Asegura que no haya márgenes adicionales */
}

.keyboard-table th {
  background-color: #23395d;
  font-weight: bold;
  position: sticky;
  width: 100px;
  /* Definir el ancho fijo */
  padding: 5px;
  /* Opcional: Espaciado dentro del encabezado */
  top: 0;
  z-index: 2;
}

.add-keyboard-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px; /* Ajusta el padding según sea necesario */
  margin-right: 10px; /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0; /* Elimina el margen superior si existe */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer;
  width: 5%; /* Porcentaje del contenedor padre */
  display: block; /* Asegúrate de que esté en la misma línea si es necesario */
}


.delete-keyboard-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px; /* Ajusta el padding según sea necesario */
  margin-right: 10px; /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0; /* Elimina el margen superior */
  cursor: pointer;
  width: 100%; /* Ocupa todo el ancho del contenedor padre */
  border-radius: 5px; /* Bordes redondeados */
  display: block; /* Hace que el botón ocupe toda la línea */
}
.delete-keyboard-btn:hover {
  background-color:  #3a5d8c;
}
.update-keyboard-btn {
  background-color: #1a2a6c;
  color: white;
  margin-top: 0;

}

.add-keyboard-btn:hover {
  background-color: #14224a;
}

.keyboard_add_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  /* Reduce el margen superior para acercarlo al botón */
  padding: 0%;
  /* Ajusta el padding para que sea responsivo/difrente monitor*/
  box-sizing: border-box;
  /* Asegura que el padding se incluya dentro del tamaño total */
  z-index: 10000;
  /* Ensure overlay is on top of everything */
}

.add_keyboard_close-btn {
  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
  /* Empuja el botón hacia la derecha */

}

.add_keyboard_close-btn:hover {
  background-color: rgb(164, 191, 216);

}

.overlay-add-keyboard {
  background-color: #1a2a6c;
}

.overlay-add-keyboard:hover {
  background-color: #14224a;
}

.overlay-delet-keyboard {
  background-color: #1a2a6c;
}

.overlay-delet-keyboard:hover {
  background-color: #d22121;
}

.inventory_checkboxes {
  display: flex;
  flex-direction: row;
  grid-template-columns: auto;
  gap: 5px;
}

.keyboard-page {
  height: 100%;
  padding: 20px;
}