.bell-icon-container {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.notification-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.bell-icon {
  font-size: 24px;
  color: #000;
}

.modal-notification-overlay {
  position: fixed;
  /* Cubre toda la pantalla */
  top: 50px;
  left: 10px;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  padding-top: 80px;
  padding-right: 30px;

  
}

.modal-notification-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  text-align: left;
  max-height: 80vh; /* Limita la altura máxima al 50% de la ventana visible */
  overflow-y: auto; /* Activa el scroll vertical si el contenido excede la altura */
  position: sticky;
}

.notification-messages {
  max-width: 400px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.notifications-section {
  padding: 5px;
  border-bottom: 1px solid #eee;
}

.section-title {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.notification-item {
  padding: 5px;
  margin-bottom: 8px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 14px;
  line-height: 1.4;
}

.notification-item.unread {
  background-color:  #0f3c6d; /* Azul claro para nuevas notificaciones */
  font-weight: bold;
  color:  white;
}
.notification-item.unread:hover {
  background-color:#22548c;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.notification-item.read {
  background-color: #f9f9f9; /* Gris claro para antiguas notificaciones */
  color: #6c757d;
}

.notification-item.read:hover {
  background-color:#d4ebff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.no-notifications {
  padding: 20px;
  text-align: center;
  color: #666;
  font-size: 14px;
}

.close-notification-button {
  padding: 10px 20px;
  background-color: #0f3c6d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
}

.notification-intro {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
  text-align: center;
  font-style: italic;
}

.notification-message {
  font-weight: bold;
  color: #07213c;
  /* Color azul */
}