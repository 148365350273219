
.promo-activities-container {
    min-height: 900px;
    padding: 20px;
    background-color: #f9f9f9;
}
.promo-activities-container h2{
    align-items: center;
}

.promo-activities-header {
    background-color: #243a5f;
    color: white;
    padding: 10px 20px;
    text-align: center;
}

.upload-content input[type="file"],
.upload-content input[type="date"],
.upload-content button {
    margin: 10px;
}

.upload-btn {
    background-color: #1a2a6c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    /* Positioning */
    z-index: 100;
}

.upload-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.upload-content {
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
}

.date-picker-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
}

.date-picker-container input[type="date"] {
    margin-left: 10px;
}

.report-display .total-row {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 10px;
    margin-bottom: 20px;
}

.total-row p{
    font-size: 1.5em;
    font-weight: bold;
    margin: 0px;
}

.card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 45%; /* Ensures both cards are the same width */
    margin: 10px;
    text-align: center;
}

.county-data {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.county-card h2 {
    font-size: 1.4em;
    margin-bottom: 10px;
}

.county-columns {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Creates a two-column layout */
    align-items: start;
    width: 100%;
}

.county-item {
    width: 100%;
    /* background-color: rgba(36, 58, 95, 0.3); */
}

.county-name {
    font-size: 1.2em;
    font-weight: bold;
}

.media-type-row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
}

.media-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.2em;
}

.media-item svg {
    font-size: 2em;
}

.media-item span {
    font-size: 1.5em;
    font-weight: bold;
}

/* Card styling for the media type section */
.media-card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 93%; /* Full width to match other cards, adjust as needed */
    margin: 20px auto; /* Add margin for spacing */
    text-align: center;
}

.media-type-row {
    display: flex;
    justify-content: space-around;
    gap: 10px;
    flex-wrap: wrap;
}

.media-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.2em;
}

.media-item svg {
    font-size: 2em;
}

.media-item span {
    font-size: 1.5em;
    font-weight: bold;
}

.unique-filenames{
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    width: 50%; /* Full width to match other cards, adjust as needed */
    margin: 20px auto; /* Add margin for spacing */
    text-align: center;
}

.unique-filenames .filenames-list {
    /* set a fixed max-height so only 5-6 items fit before scrolling */
    max-height: 150px; 
    overflow-y: auto;       /* enable vertical scrolling */
    border: 1px solid #ccc; /* optional: gives a visible boundary */
    padding: 0.5rem;        /* some padding */
  }
  
  .filename-item {
    white-space: nowrap;    /* if you want to prevent text wrapping */
  }


