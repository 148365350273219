.employees {
  padding: 20px;
  position: relative;
  /* Ensure proper stacking context */
}

.employees-header {
  background-color: #23395d;
  color: white;
  padding: 8px 8px;
  z-index: 15;
  /* Keeps the header above other content */
  text-align: center;
  position: sticky;
  margin-bottom: 5px;
  top: 0;
}

.employees-page {
  height: 100%;
  padding: 20px;
}

@media (max-width: 768px) {
  .employees-table-container {
    max-height: 50vh;
    /* Reduce la altura en pantallas más pequeñas */
  }

  .employees-table th,
  .employees-table td {
    padding: 3px;
    font-size: 14px;
    /* Reduce el tamaño de fuente */
  }
}

.employees-table-container {
  width: 100%;
  max-height: 67vh;
  /* El contenedor ocupará el 67% del alto de la pantalla */
  /* Adjust height to make space for header, footer, and other elements */
  overflow: auto;
  /* Allow both vertical and horizontal scrolling inside the table container */
  padding-top: 0px;
  box-sizing: border-box;
}

.employees-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Establece un ancho fijo para las columnas */
}

.employees-table th,
.employees-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 150px;

}

.employees-table th {
  position: sticky;
  top: 0;
  z-index: 1;
  text-align: center;
  table-layout: fixed;

}
.employees-table th:nth-child(6),
.employees-table td:nth-child(6) {
  width: 320px; /* Ancho específico para la segunda columna */
}
.employees-table th:nth-child(8),
.employees-table td:nth-child(8) {
  width: 250px; /* Ancho específico para la primera columna */
}
.employees-table th:nth-child(10),
.employees-table td:nth-child(10) {
  width: 320px; /* Ancho específico para la primera columna */
}

.employees-table th:nth-child(1),
.employees-table td:nth-child(2),
.employees-table th:nth-child(14)
 {
  width: 50px; /* Ancho específico para estas columnas */
}

.add-employees-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior si existe */
  border-radius: 5px;
  /* Bordes redondeados */
  cursor: pointer;
  width: 10%;
  /* Porcentaje del contenedor padre */
  display: block;
  /* Asegúrate de que esté en la misma línea si es necesario */
}


.buttons-container {
  display: flex;
  flex-direction: row;
  /* Alinea los botones horizontalmente */
  gap: 5px;
  /* Espacio entre los botones */
  width: 10%;
  /* Porcentaje del contenedor padre */
}

.delete-employees-btn {
  background-color: #23395d;
  color: white;
  min-width: 120px;
  /* Ancho mínimo para mantener el tamaño constante */
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 35px;
  border-radius: 5px;
}

.delete-employees-btn:hover {
  background-color: #3a5d8c;

}

.update-employees-btn {
  background-color: #1a2a6c;
  color: white;
  margin-top: 0;

}

.add-employees-btn:hover {
  background-color: #3a5d8c;
}

.employees-search {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  /* Asegura que el ancho del contenedor sea constante */
  max-width: 600px;
  /* O el tamaño que mejor se ajuste a tu diseño */
  flex-wrap: nowrap;
  /* Evita que los elementos se muevan a otra línea */
  margin-bottom: 20px;
  /* Espacio debajo del div */
}

.employees-search input,
.employees-search select,
.employees-search button {
  padding: 10px;
  border-radius: 5px;
  height: 45px;
  /* Ensure all elements have the same height */
  width: max-content;
}

.employees-search button {
  background-color: #23395d;
  color: white;
  min-width: 120px;
  /* Ancho mínimo para mantener el tamaño constante */
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 45px;
  border-radius: 5px;
  /* Ajusta este valor para mayor redondez */
  transition: background-color 0.3s, transform 0.2s;
}


.employees-search button:hover {
  background-color: #3a5d8c;

}

.employees_add_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0%;
  /* Ajusta el padding para que sea responsivo/difrente monitor*/
  box-sizing: border-box;
  /* Asegura que el padding se incluya dentro del tamaño total */
  z-index: 10000;
  /* Ensure overlay is on top of everything */
}

.overlay-content-employees {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 650px;
  /* Ajusta el ancho según tus necesidades */
  max-height: 90vh;
  /* Limita la altura al 80% del viewport */
  overflow-y: auto;
  /* Permite scroll interno si el contenido es demasiado alto */
  box-sizing: border-box;
  /* Asegura que el padding no incremente el tamaño total */
}

.overlay-content-employees input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.overlay-content-employees select {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.add_employees_close-btn {

  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
  /* Empuja el botón hacia la derecha */

}

.add_employees_close-btn:hover {
  background-color: rgb(164, 191, 216);

}

.overlay-add-employees {
  padding: 20px 20px;
  border: none;
  background-color: #164678;
  border-radius: 5px;
  cursor: pointer;
}

.overlay-delete-employees {
  padding: 10px 20px;
  border: none;
  background-color: #ec2b2b;
  border-radius: 5px;
  cursor: pointer;
}

.overlay-add-employees:hover {
  background-color: #14224a;
}

.notas_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 15px; /* Tamaño de letra aumentado */
}

.input-field {
  width: 810px; /* Ancho */
  margin: 0 auto; /* Centrado horizontal */
  display: block; /* Comportamiento de bloque */
  border-radius: 8px; /* Bordes redondeados */
  padding: 10px; /* Espaciado interno para mejorar la apariencia */
  
}
.printNotes {
  width: 800px; /* Ancho */
  margin: 0 auto; /* Centrado horizontal */
  display: block; /* Comportamiento de bloque */
  border: 2px solid#14224a;; /* Borde de 2px de color azul */
  font-size: 10px; /* Tamaño de letra aumentado */

}

.employees-notes {
  max-width: 150px;
  /* Ajusta el ancho máximo para esta columna */
  overflow-x: auto;
  /* Permite el scroll horizontal si el contenido es demasiado largo */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
  padding: 10px;
  /* Agrega espaciado interno para mejor apariencia */
  box-sizing: border-box;
  /* Asegura que el padding no afecte el ancho total */
}

.input-field:focus {
  border-color: #0056b3; /* Cambia el color del borde cuando está enfocado */
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra para resaltar el campo */
}


.Employees_checkboxes {
  display: flex;
  flex-direction: row;
  grid-template-columns: auto;
  gap: 5px;
}


.logo-container {
  display: flex;
  /* Para centrar el logo */
  position: relative;
  /* Habilita el posicionamiento absoluto para el botón */
  margin-bottom: 0;
  /* Reduce o elimina el espacio debajo del logo */
  color: #F5F5F5;
}

.logo {
  max-width: 200%;
  height: 130px;
  padding: 0;
}

.close-button {
  position: relative;
  /* Alinea el botón con la parte superior */
  margin-left: auto; /* Empuja el botón hacia la derecha */
  right: 0; /* Alinea el botón completamente a la derecha */
  /* Mueve el botón hacia la esquina derecha */
  padding: 5px 10px;
  background-color: #ff0000;
  color: white;
  border: none;
  top: 10%; /* Centra verticalmente */
  transform: translateY(-400%); /* Ajusta la posición vertical */
  cursor: pointer;
}

.log-table {
  margin-top: 0;
  /* Elimina cualquier margen superior que tenga la tabla */
  padding-top: 0;
  /* Elimina cualquier relleno superior si lo tiene */
  width: 100%;
  /* Ajusta según sea necesario */
  border-collapse: collapse;
  /* Si necesitas unir más las celdas */
}

.checkbox-returned {
  display: flex;
  font-size: 8px; /* Ajusta el tamaño del texto */
  margin: 0; /* Elimina márgenes alrededor del div */
  padding: 0; /* Elimina relleno dentro del div */

}
.checkbox-returned label {
  display: flex; /* Alinea el checkbox y el texto juntos */
  flex-direction: column; /* Pone el texto y el input en columna */
  font-size: 6px; /* Tamaño de fuente */
  color: #333; /* Color del texto */
  cursor: pointer; /* Cambia el cursor cuando pasas sobre los labels */
}

.assign-equipment {
  display: flex;
  gap: 20px;
}

.available-items {
  max-width: 300px;
  border: 1px solid #ccc;
  padding: 10px;
  overflow-y: auto;
  height: 260px; /* Reduce la altura para dejar espacio al encabezado */
  margin-top: 40px; /* Agrega espacio para el encabezado */
}

.scrollable-list {
  max-height: 300px; /* Controla la altura máxima */
  overflow-y: auto; /* Habilita el desplazamiento vertical */
  border: 2px solid #ccc; /* Bordes como el de un fieldset */
  padding: 10px; /* Espaciado interno */
  margin-top: 10px; /* Espaciado del encabezado */
  border-radius: 5px; /* Bordes redondeados */
  background-color: #fff; /* Fondo blanco */
}


.available-item {
  padding: 5px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  cursor: pointer;
}

.available-item:hover {
  background-color: #e6e6e6;
}
/* Estilo para el fieldset */
fieldset {
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  margin: 15px 0;
  background-color: #f9f9f9;
  width: 178px; /* Cambia este valor según tus necesidades */
}

/* Estilo para el legend */
legend {
  font-size: 1.1rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Estilo para las opciones */
.equipment-option {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 10px; /* Espacio entre el checkbox y la etiqueta */
}

/* Estilo para el checkbox */
.equipment-option input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-right: 5px; /* Espaciado adicional si no usas gap */
  accent-color: #164678; /* Cambia el color del checkbox al activarlo */
}

/* Estilo para la etiqueta */
.equipment-option label {
  font-size: 1rem;
  color: #555;
  margin-left: 2px; /* Espaciado entre el checkbox y el texto *
  cursor: pointer; /* Hace clic en la etiqueta para marcar el checkbox */
}

/* Mejora la interacción al pasar el cursor */
.equipment-option:hover label {
  color:  #0e2640; /* Cambia el color de texto al pasar el cursor */
}


.available-item:hover {
  background-color: #f0f0f0; /* Color al pasar el mouse */
}

.available-item.selected {
  background-color: #164678; /* Color de selección */
  color: #fff;
  font-weight: bold;
}


.assign-button{
  background-color: #164678; /* Color de selección */
  color: #fff;
  font-weight: bold;
}
.message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: bold;
}

.message.error {
  background-color: #f8d7da;
  color: #721c24;
  border: 1px solid #f5c6cb;
}

.message.success {
  background-color: #d4edda;
  color: #155724;
  border: 1px solid #c3e6cb;
}
.no-items {
  text-align: center;
  color: gray;
  font-style: italic;
  padding: 20px;
  font-size: 1.1rem;
}
.available-button {
  padding: 10px 20px;
  background-color: #164678;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.available-button:hover {
  background-color: #164678;
}

.select-container {
  margin-top: 15px;
  text-align: center;
}
.employees-location {
  max-width: 150px;
  /* Ajusta el ancho máximo para esta columna */
  overflow-x: auto;
  /* Permite el scroll horizontal si el contenido es demasiado largo */
  white-space: nowrap;
  /* Evita que el texto se rompa en múltiples líneas */

  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
  overflow-x: auto;
  /* Habilita el scroll horizontal */
  padding: 10px;
  /* Agrega espaciado interno para mejor apariencia */
  box-sizing: border-box;
  /* Asegura que el padding no afecte el ancho total */


}
