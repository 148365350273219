.manage-users-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 9999;
}

.manage-users-content {
    background-color: #fff;
    padding: 20px; /* Reduced padding for a cleaner look */
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 600px;
    max-height: 90vh;
    position: relative;
    display: flex;
    flex-direction: column;
}

.manage-users-content {
    pointer-events: auto;
}

.manage-users-modal {
    pointer-events: auto;
}

/* This ensures the modal closes when clicking outside */
.manage-users-modal {
    pointer-events: auto;
}

.man-u-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    color: #333;
    cursor: pointer;
}

.users-list {
    margin-top: 10px; /* Slightly reduce margin for a tighter layout */
    overflow-y: auto;
    flex-grow: 1;
}

.user-item {
    padding: 10px 15px;
    margin-bottom: 8px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s;
}

.user-item {
    padding: 10px 15px;
    margin-bottom: 8px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s;
}

.user-item:hover {
    background-color: #f5f5f5; /* Hover effect */
}

.user-item.expanded {
    flex-direction: column;
    align-items: flex-start;
    background-color: #eef2ff; /* Different background when expanded */
}
.user-item {
    padding: 10px 15px;
    margin-bottom: 8px;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    width: 100%;
    box-sizing: border-box;
    transition: background-color 0.3s;
}

.user-item:hover {
    background-color: #f5f5f5; /* Hover effect */
}

.user-item.expanded {
    flex-direction: column;
    align-items: flex-start;
    background-color: #eef2ff; /* Different background when expanded */
}

.user-item p {
    margin: 0;
}

.user-email {
    width: 50%; /* Increase email width */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.user-status {
    flex: 1;
    text-align: center;
}

.user-details {
    margin-top: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    width: 100%;
}

.user-details p {
    margin: 4px 0;
    width: 100%;
}

.user-actions {
    display: flex;
    justify-content: center; /* Center the buttons */
    gap: 20px; /* Add space between buttons */
    width: 100%;
    margin-top: 10px;
}

.disable-user-btn,
.reset-password-btn {
    background-color: #23395d;
    color: #fff;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.disable-user-btn:hover,
.reset-password-btn:hover {
    background-color: #1d2b4a;
}

.disable-user-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

/* Close button */
.collapse-btn {
    background-color: #dc3545;
    color: #fff;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
}

.collapse-btn:hover {
    background-color: #b52a36;
}
