.report-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  padding: 10px;
}
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    min-width: 400px;
    max-width: 700px;
    overflow-y: auto;
    position: relative; /* Added this to make the close button relative to modal-content */

  }
  
  .close-btn_updateReport {
    background: transparent;
    border: none;
    font-size: 20px;
    position: absolute;
    color: #333;
    top: 10px;
    right: 10px; /* Adjusted to move the button to the corner */
    cursor: pointer;
  }
  .close-btn_updateReport:hover {
    background-color: rgb(164, 191, 216) !important;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input[type="text"], .form-group input[type="email"], .form-group input[type="password"], .form-group input[type="number"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }

/* Radio buttons styles */
.form-group input[type="radio"] {
  width: auto;
  padding: 0;
  border-radius: 0;
  border: 1px solid #ddd;
}

/* Checkboxes styles */
.form-group input[type="checkbox"] {
  width: auto;
  padding: 0;
  border: none;
  border-radius: 0;
}
.form-group input[type="time"] {
  width: auto;
  height: 46px;
  padding: 0;
  border: 1px solid #ddd;
  border-radius: 0;
}
  
  .save-btn {
    background: #1a2a6c;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .save-btn:hover {
    background-color: #14224a;
  }
  
  select{
    width: 100%;
    height: 46px;
    border: 1px solid #ddd;
  }
  .radio-group {
    justify-content: center;
    gap: 20px;
  }