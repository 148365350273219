.inventory-page {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  
  .equipment-table {
    margin-bottom: 5px;
  }
  
  .equipment-table h2 {
    text-align: center;
    color: #23395d;
  }
  
  .equipment-table table {
    width: 80%; /* Ajusta el ancho fijo que desees */
    border-collapse: collapse;

}

  .equipment-table th,
  .equipment-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .equipment-table th {
    background-color: #f2f2f2;
    color: #23395d;
    font-weight: bold;
  }
  

  