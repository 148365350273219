/* Modal Overlay */
.shift-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal Container */
.shift-modal {
    background: white;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tab-content {
    margin: 20px;
}

/* Row Styling */
.shift-modal .modal-row {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

/* Two-column layout for time pickers */
.shift-modal .modal-row-split {
    display: flex;
    flex-direction: row;
    gap: 10px;
    /* Space between the two columns */
    width: 100%;
    /* Make sure the row spans full width */
}

.shift-modal .modal-row-split>div {
    flex: 1;
    /* Each column takes up equal space */
    display: flex;
    flex-direction: column;
    /* Ensures label and input stack vertically */
    gap: 1px;
    /* Space between label and input */
}

/* Buttons Row */
.shift-modal .modal-buttons {
    display: flex;
    flex-direction: row;
    /* Ensure buttons are laid out in a row */
    justify-content: center;
    /* Align buttons to the right */
    gap: 10px;
    /* Space between buttons */
    margin-top: 15px;
    /* Add some spacing above the row for visual separation */
    width: 100%;
    /* Ensure row spans full width */
}

/* Button Styling */
.shift-modal .modal-buttons button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    cursor: pointer;
}

/* Save Button */
.shift-modal .modal-buttons .save-button {
    background-color: #1a2a6c;
    color: white;
}

.shift-modal .modal-buttons .save-button:hover {
    background-color: #14224a;
}

/* Cancel Button */
.shift-modal .modal-buttons .cancel-button {
    background-color: #ccc;
    color: black;
}

.shift-modal .modal-buttons .cancel-button:hover {
    background-color: #999;
}

/* Delete Button */
.shift-modal .modal-buttons .delete-button {
    background-color: #ff4d4f;
    color: white;
    transition: background-color 0.3s ease;
}

.shift-modal .modal-buttons .delete-button:hover {
    background-color: #d9363e;
}

/* Update Button */
.shift-modal .modal-buttons button {
    background-color: #1a2a6c;
    color: white;
}

.shift-modal .modal-buttons button:hover {
    background-color: #14224a;
}

.shift-modal h3 {
    margin: 0px;
}

/* Select Dropdown */
.shift-modal select {
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease;
    height: 32px;
}

.shift-modal select:focus {
    border-color: #3174ad;
}

.shift-modal select:hover {
    border-color: #3174ad;
}

/* Inputs */
.shift-modal input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease;
}

.shift-modal input:focus {
    border-color: #3174ad;
}

/* Inputs inside shift-modal */
.shift-modal input[type="time"],
.shift-modal input[type="date"] {
    padding: 8px;
    /* Adjust padding for better appearance */
    border: 1px solid #ccc;
    /* Light gray border */
    border-radius: 4px;
    /* Slightly rounded corners */
    font-size: 14px;
    /* Adjust font size */
    outline: none;
    /* Remove default outline */
    height: 32px;
    width: 100%;
    /* Ensure it takes full width within its container */
    box-sizing: border-box;
    /* Include padding and border in height calculation */
    transition: border-color 0.3s ease;
    /* Smooth transition for border focus */
}

.shift-modal input[type="time"]:focus,
.shift-modal input[type="date"]:focus {
    border-color: #3174ad;
    /* Blue border on focus */
    box-shadow: 0 0 3px rgba(49, 116, 173, 0.5);
    /* Optional focus shadow */
}

/* Ensure consistent spacing for modal rows */
.shift-modal .modal-row {
    margin-bottom: 16px;
    /* Add spacing between rows */
}

.shift-modal .modal-row label {
    display: block;
    margin-bottom: 8px;
    /* Add space between label and input */
    font-weight: bold;
    /* Make labels stand out */
    font-size: 14px;
    /* Consistent label font size */
}

/* Container for tabs */
.shift-modal .shift-modal-tabs {
    display: flex;
    width: 100%;
    /* Occupy full width */
    margin-bottom: 16px;
    /* Add some spacing below the tabs */
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    /* Optional shadow for the tabs container */
}

/* Individual tab buttons */
.shift-modal .shift-modal-tabs button {
    flex: 1;
    /* Make both tabs take 50% of the width */
    padding: 12px 0;
    /* Add padding for height */
    font-size: 16px;
    /* Adjust font size */
    text-align: center;
    /* Center-align the text */
    background-color: #f0f0f0;
    /* Default light gray background */
    border: none;
    /* Remove button border */
    outline: none;
    /* Remove focus outline */
    cursor: pointer;
    /* Show pointer cursor */
    transition: background-color 0.3s, box-shadow 0.3s;
    /* Smooth transition effects */
}

/* Active tab styling */
.shift-modal .shift-modal-tabs button.active {
    background-color: #1a2a6c;
    /* Slightly darker gray for active tab */
    box-shadow: inset 0px -3px 8px rgba(77, 126, 241, 0.2);
    /* Inner shadow for the active tab */
    font-weight: bold;
    /* Highlight active tab text */
    color: white;
}

/* Hover effect for inactive tabs */
.shift-modal .shift-modal-tabs button:not(.active):hover {
    background-color: #e0e0e0;
    /* Slightly darker on hover */
}

/* Rounded corners for the tabs container */
.shift-modal .shift-modal-tabs button:first-child {
    border-top-left-radius: 8px;
    /* Round top-left corner */
}

.shift-modal .shift-modal-tabs button:last-child {
    border-top-right-radius: 8px;
    /* Round top-right corner */
}


@media screen and (max-width: 480px) {

    .shift-modal {
        background: white;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
        max-width: 500px;
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .shift-modal .modal-row-split {
        flex-direction: column;
        /* Stack fields instead of side-by-side */
        gap: 5px;
        /* Reduce space */
    }

    .shift-modal .modal-buttons {
        flex-direction: column;
        /* Stack buttons */
        gap: 8px;
        /* Reduce space */
    }

    .shift-modal .modal-buttons button {
        padding: 8px 15px;
        /* Reduce padding */
        font-size: 12px;
        /* Make text smaller */
    }

    .navigator-selection {
        max-height: 120px;
        /* Limit height */
        overflow-y: auto;
        /* Enable scrolling */
        padding-right: 5px;
        /* Ensure smooth scrolling */
    }


}