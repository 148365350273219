/* FormStyles.css
   -----------------------------------------------------------
   By scoping everything under .dynamic-report-root and 
   .form-page-root, we ensure these styles apply ONLY to the 
   pages/components that include those root classes in their JSX.
   -----------------------------------------------------------
*/

/* ====== DynamicReport Scopes ====== */
.dynamic-report-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 20px;
  }
  
  .dynamic-report-root .dynamic-report-container {
    width: 100%;
  }
  
  .dynamic-report-root .dynamic-report-header {
    width: 100%;
    background-color: #23395d;
    color: white;
    padding: 10px 20px;
    text-align: center;
  }
  
  .dynamic-report-root .report-name-section {
    margin-bottom: 1rem;
  }
  
  /* .card-section within .dynamic-report-root */
  .dynamic-report-root .card-section {
    position: relative;
    border: 1px solid #ccc;
    margin: 20px auto 1rem auto;
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    max-width: 700px;
  }
  
  .dynamic-report-root .card-label-input {
    width: 100%;
    font-size: 1rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  
  .dynamic-report-root .field-editor {
    margin: 0.5rem 0;
  }
  
  .dynamic-report-root .select-options {
    margin-left: 1rem;
    margin-bottom: 0.5rem;
  }
  
  /* Common button styles within .dynamic-report-root */
  .dynamic-report-root
    .remove-field-button,
  .dynamic-report-root
    .remove-card-button,
  .dynamic-report-root
    .add-field-buttons
    button,
  .dynamic-report-root
    .add-card-button,
  .dynamic-report-root
    .save-report-button {
    padding: 8px 12px;
    margin: 5px;
    background-color: #1a2a6c;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .dynamic-report-root
    .remove-field-button:hover,
  .dynamic-report-root
    .remove-card-button:hover,
  .dynamic-report-root
    .add-field-buttons
    button:hover,
  .dynamic-report-root
    .add-card-button:hover,
  .dynamic-report-root
    .save-report-button:hover {
    background-color: #14224a;
  }
  
  /* Preview styles for .dynamic-report-root */
  .dynamic-report-root .preview-card {
    border: 1px solid #ddd;
    margin: 20px auto 1rem auto;
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    max-width: 700px;
  }
  .dynamic-report-root .preview-field {
    margin-bottom: 0.5rem;
  }
  
  /* ====== FormPage Scopes ====== */
  .form-page-root {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    min-height: 100vh;
    padding: 20px;
  }
  
  .form-page-root .form-page-container {
    width: 100%;
  }
  
  .form-page-root .dynamic-report-header {
    width: 100%;
    background-color: #23395d;
    color: white;
    padding: 10px 20px;
    text-align: center;
  }
  
  /* .card-section within .form-page-root */
  .form-page-root .card-section {
    position: relative;
    border: 1px solid #ccc;
    margin: 20px auto 1rem auto;
    padding: 1rem;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    max-width: 700px;
  }
  
  /* Field styles in .form-page-root */
  .form-page-root .field-view {
    margin-bottom: 1rem;
  }
  .form-page-root .field-view label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
  }
  
  /* Buttons in form-page-container */
  .form-page-root .form-page-container button {
    padding: 10px 15px;
    margin: 5px;
    background-color: #1a2a6c;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .form-page-root .form-page-container button:hover {
    background-color: #14224a;
  }
  
  /* Example classes for radio/checkbox groups */
  .form-page-root .radio-group,
  .form-page-root .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .form-page-root .radio-group label,
  .form-page-root .checkbox-group label {
    font-weight: normal;
  }
  
  /* Required field asterisk */
  .form-page-root .required-asterisk::after {
    content: "*";
    color: red;
    margin-left: 5px;
  }
  
  .form-page-root .loading-spinner {
    margin-top: 1rem;
  }
  
  /* ====== Card Hover Buttons (for admin) ======
     We only show them on hover if .admin-hoverable is present.
  */
  .admin-hoverable {
    position: relative;
  }
  .admin-hoverable:hover .card-hover-buttons {
    opacity: 1;
    pointer-events: auto;
  }
  
  .card-hover-buttons {
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s;
  }
  .top-buttons {
    top: -15px;
  }
  .bottom-buttons {
    bottom: -15px;
  }
  .hover-button {
    background-color: #1a2a6c;
    color: #fff;
    border: none;
    padding: 5px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.8rem;
  }
  
  /* ====== Modal (for admin only) ====== */
  .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .modal-backdrop .form-modal-content {
    background: #fff;
    padding: 1.5rem;
    border-radius: 6px;
    max-width: 600px;
    width: 100%;
    height: 90%;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  }

  .modal-backdrop .form-modal-content label{
    margin-right: 10px;
    }  
  /* Example: styling for new field rows in the modal */
  .admin-new-field {
    background-color: #fafafa;
    border: 1px solid #ddd;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
  }

  /* New styles for card title editing */
.card-title-display {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card-title-display h2 {
    margin: 0;
    padding: 0;
  }
  
  .card-title-editing {
    display: flex;
    align-items: center;
  }
  
  .card-title-editing input {
    font-size: 1.2rem;
    padding: 4px;
    margin-right: 0.5rem;
  }
  
  .edit-button {
    background: transparent;
    border: none;
    color: #1a2a6c;
    cursor: pointer;
    font-size: 0.9rem;
  }
  
  
  /* ===== RESPONSIVE for both scopes ===== */
  @media (max-width: 480px) {
    .dynamic-report-root,
    .form-page-root {
      padding: 10px;
    }
  
    .dynamic-report-root .card-section,
    .form-page-root .card-section {
      margin: 10px auto 1rem auto;
      padding: 0.75rem;
    }
  
    /* Adjust inputs to full width */
    .dynamic-report-root .report-name-section input,
    .form-page-root .field-view input,
    .form-page-root .field-view select {
      width: 100%;
      box-sizing: border-box;
    }
  
    /* Smaller buttons */
    .hover-button {
      padding: 3px 6px;
      font-size: 0.7rem;
    }
  }
  