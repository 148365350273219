.locations-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.locations-modal {
    background: #fff;
    border-radius: 8px;
    padding: 20px;
    width: 800px; /* Increased width */
    height: 500px;
    display: flex;
    flex-direction: column;

}

.locations-container {
    display: flex;
    gap: 20px;
    width: 100%;
    height: 500px; /* Set a fixed height for consistent sizing */
    overflow: hidden;
}

.locations-form {
    flex: 1;
    padding: 10px;
    background: #f7f7f7;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.modal-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
    overflow-y: auto; /* Enable scrolling when content exceeds height */
    max-height: 100%; /* Ensure it doesn't overflow the form container */
    padding-right: 10px; /* Add padding to prevent content from touching the edge */
}

.modal-form label {
    font-size: 14px;
    color: #333;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.modal-form input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.locations-list-container {
    flex: 1;
    max-height: 400px;
    overflow-y: auto;
    padding: 10px;
    border-radius: 8px;
    background: #fff;
}

.locations-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.locations-list li {
    padding: 10px;
    border-bottom: 1px solid #eee;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.edit-button {
    background: #28a745;
    color: #fff;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 5px;
}

.edit-button:hover {
    background: #218838;
}

.add-button {
    background: #3174ad;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.cancel-button {
    background: #ccc;
    color: #333;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
}

/* Color preview button */
.color-preview-button {
    width: 40px;
    height: 40px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    display: inline-block;
    margin-top: 5px;
}

/* Color picker overlay */
.color-picker-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
    z-index: 1050;
}

/* Close button for color picker */
.close-color-picker {
    position: absolute;
    top: 5px;
    right: 5px;
    background: #f44336;
    color: white;
    border: none;
    padding: 5px 8px;
    border-radius: 50%;
    cursor: pointer;
}

.close-color-picker:hover {
    background: #d32f2f;
}
