.duplicates-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
  }
  
  .duplicates-modal-content {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    max-height: 90vh;
    overflow-y: auto;
    min-width: 60%; /* Make the modal wider */
    max-width: 80%; /* Optional: Limit the max width */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  }
  
  .duplicates-modal-content h2 {
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 1.8rem;
    color: #333;
  }
  
  .duplicate-group {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    padding: 10px;
    border-radius: 5px;
  }
  
  .duplicate-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .duplicate-summary:hover {
    background-color: #ececec;
  }
  
  .duplicate-details {
    margin-top: 15px;
    display: flex; /* Ensure items are aligned side by side */
    gap: 15px; /* Add spacing between items */
    overflow-x: auto; /* Handle horizontal overflow if too many duplicates */
  }
  
  .duplicate-item {
    flex: 1 1 calc(33.333% - 10px); /* Each duplicate takes up equal width */
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .duplicate-item h4 {
    margin-bottom: 10px;
    color: #444;
  }
  
  .duplicate-item p {
    margin: 5px 0;
    font-size: 0.9rem;
    color: #555;
  }
  
  .merge-btn,
  .remove-btn {
    background-color: #23395d;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 10px 10px 0;
  }
  
  .merge-btn:hover,
  .remove-btn:hover {
    background-color: #14224a;
  }
  
  .close-modal-btn {
    margin-top: 15px;
    padding: 10px 20px;
    background: #ccc;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
  }
  
  .close-modal-btn:hover {
    background: #bbb;
  }
  