.printer-table-container {
  width: 100%;
  max-height: 67vh;
  /* El contenedor ocupará el 67% del alto de la pantalla */
  /* Adjust height to make space for header, footer, and other elements */
  overflow: auto;
  /* Allow both vertical and horizontal scrolling inside the table container */
  padding-top: 0px;
  box-sizing: border-box;
}

.printer-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.printer-table th,
.printer-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 150px;
  table-layout: auto;
  /* Permite que las columnas se ajusten al contenido */
  padding: 8px 10px;
  /* Reduce el padding dentro de las celdas */
  margin: 0;
  /* Asegura que no haya márgenes adicionales */
}

.printer-table th {
  background-color: #23395d;
  font-weight: bold;
  position: sticky;
  width: 100px;
  /* Definir el ancho fijo */
  padding: 5px;
  /* Opcional: Espaciado dentro del encabezado */
  top: 0;
  z-index: 2;
}

.add-printer-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior si existe */
  border-radius: 5px;
  /* Bordes redondeados */
  cursor: pointer;
  width: 10%;
  /* Porcentaje del contenedor padre */
  display: block;
  /* Asegúrate de que esté en la misma línea si es necesario */
}

.add-printer-btn:hover {
  background-color: #3a5d8c;
}

.delete-printer-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior */
  cursor: pointer;
  width: 100%;
  /* Ocupa todo el ancho del contenedor padre */
  border-radius: 5px;
  /* Bordes redondeados */
  display: block;
  /* Hace que el botón ocupe toda la línea */
}

.delete-printer-btn:hover {
  background-color: #3a5d8c;
}

.printer_add_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  /* Fija el fondo del overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  /* Asegura que el overlay esté por encima de todo */
  overflow: hidden;
  /* Evita scroll en el overlay principal */
}

.add_printer_close-btn {

  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
  /* Empuja el botón hacia la derecha */

}

.add_printer_close-btn:hover {
  background-color: rgb(164, 191, 216);

}

.overlay-add-printer {
  background-color: #1a2a6c;
}

.overlay-add-printer:hover {
  background-color: #14224a;
}

.overlay-delet-printer {
  background-color: #1a2a6c;
}

.overlay-delet-printer:hover {
  background-color: #d22121;
}

.color-refill-list {
  list-style-type: none;
  /* Elimina los puntos de la lista */
  padding-left: 0;
  /* Elimina el padding izquierdo */
  margin-top: 10px;
  /* Añade un margen superior */
  max-height: 150px;
  /* Establece la altura máxima del contenedor para activar el scroll */
  overflow-y: auto;
  /* Habilita el scroll vertical */
  overflow-x: hidden;
  /* Evita el scroll horizontal */
}

.color-refill-item {
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
  width: 100%;
  /* Hace que cada elemento ocupe el 100% del ancho disponible */
  box-sizing: border-box;
  /* Asegura que padding y border estén incluidos en el ancho */
  display: block;
  /* Asegura que los elementos ocupen todo el ancho disponible */
  text-align: center;
  /* Centra el texto dentro del contenedor */
  white-space: nowrap;
  /* Impide que las fechas se partan en varias líneas */
  overflow: hidden;
  /* Elimina cualquier desbordamiento de texto en el <li> */

}

.black-refill-list {
  list-style-type: none;
  /* Elimina los puntos de la lista */
  padding-left: 0;
  /* Elimina el padding izquierdo */
  margin-top: 10px;
  /* Añade un margen superior */
  max-height: 150px;
  /* Establece la altura máxima del contenedor para activar el scroll */
  overflow-y: auto;
  /* Habilita el scroll vertical */
  overflow-x: hidden;
  /* Evita el scroll horizontal */


}

.black-refill-item {
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 5px;
  font-size: 16px;
  color: #333;
  width: 100%;
  /* Hace que cada elemento ocupe el 100% del ancho disponible */
  box-sizing: border-box;
  /* Asegura que padding y border estén incluidos en el ancho */
  display: block;
  /* Asegura que los elementos ocupen todo el ancho disponible */
  text-align: center;
  /* Centra el texto dentro del contenedor */
  white-space: nowrap;
  /* Impide que las fechas se partan en varias líneas */
  overflow: hidden;
  /* Elimina cualquier desbordamiento de texto en el <li> */

}

.printer-black_ink {
  max-width: 150px;
  /* Ajusta el ancho máximo para esta columna */
  overflow-x: auto;
  /* Permite el scroll horizontal si el contenido es demasiado largo */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
  padding: 10px;
  /* Agrega espaciado interno para mejor apariencia */
  box-sizing: border-box;
  /* Asegura que el padding no afecte el ancho total */
}

.printer-color_ink {
  max-width: 150px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  box-sizing: border-box;
}

.printer-Description {
  max-width: 150px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  box-sizing: border-box;

}

.delete-date-button {
  margin-left: 10px;
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.delete-date-button:hover {
  background-color: darkred;
}

.printer-page {
  height: 100%;
  padding: 20px;
}