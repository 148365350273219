.reportsTabs-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;

}

.tabs {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.tabs button {
    padding: 10px 10px;
    color: white;
    cursor: pointer;
    background-color: #1a2a6c;
    border: none;
    border-radius: 4px;
    border-bottom: 2px solid transparent;
    font-size: 16px;
}

.tabs button.active {
    border-bottom: 2px solid #185da1;
    background-color: #6780a7;
}

.tab-content {
    margin-top: 20px;
}
