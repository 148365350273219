.weeklyReports-container{
    padding: 20px;
}

.weeklyreports-header {
    text-align: center;
    background-color: #23395d;
    color: white;
    padding: 10px 20px;
  }

  .datePicker {
    display: flex;
    justify-content: flex-start;
    gap: 10px; /* Add some space between the date pickers */
    margin: 20px 0;
  }
  
  .dp {
    display: flex;
    align-items: center;
  }
.react-datepicker-wrapper{
margin-left: 10px;
}

.weeklyReports-table {
    width: 80%;
    max-width: fit-content;
    table-layout: auto; /* Ensure the table adjusts to the content */
    margin: 0px;
  }
  
  .weeklyReports-table td,
  .weeklyReports-table th {
    white-space: nowrap; /* Prevent text from wrapping */
    padding: 8px; /* Add some padding for better readability */
  }
  
  .weeklyReports-table th {
    text-align: left; /* Align headers to the left for consistency */
  }