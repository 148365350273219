.dashboard {
  padding: 20px;
}

.dashboard-header {
  text-align: center;
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
}

.menu-icon {
  font-size: 24px;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-info span {
  margin-left: 10px;
}

.dashboard-stats {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;   /* Prevent them from wrapping onto multiple lines */
  margin: 20px 0;
  max-width: 1400px;
  margin: 20px auto 20px;
}

.dashboard-stats p{
  font-weight: bold;
}

.stat {
  background-color: #f5f5f5;
  padding: 0px;
  border-radius: 8px;
  width: 30%;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}

.dashboard-content {
  background-color: #f9f9f9;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  max-width: 1400px;
  margin: 20px auto 20px;
}

.navigate-rank-dashboard-btn{
  background-image: linear-gradient(92.88deg, #455EB5 9.16%, #5643CC 43.89%, #673FD7 64.72%);
  border-radius: 8px;
  border-style: none;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 500;
  height: 2rem;
  width: 150px;
  text-align: center;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all .5s;
  touch-action: manipulation;
}

.navigate-rank-dashboard-btn:hover {
  box-shadow: rgba(80, 63, 205, 0.5) 0 1px 30px;
  transition-duration: .1s;
}
.progress-bar {
  width: 100%;
  background-color: #e0e0e0;
  height: 20px;
  border-radius: 10px;
  margin-top: 10px;
}

.progress {
  height: 100%;
  background-color: #4caf50;
  border-radius: 10px;
  transition: width 0.5s ease-in-out;
}

.progress-bar .progress.overall-progress {
  background-color: #23395d; /* Distinct color for the overall QHP progress */
}

.dashboard-main {
  display: flex;
  flex-direction: column;
  gap: 20px; /* Add some spacing between the rows */
  max-width: 1400px;
  margin: 20px auto 20px;
}

.appointments-today {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  margin: 0 10px;
  box-sizing: border-box;
  /* min-width: 50%; */
}
.enrollments-by-month {
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  width: 70%; /* Ensure full width */
  text-align: center;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 0 auto 0 auto;
  max-width: 800px;
}


.pie-charts-container {
  display: flex;
  justify-content: center; /* Center the pie charts horizontally */
  width: 70%;
  margin: 0 auto; /* Center the pie charts */
  max-width: 1400px;
}

.pie-chart-section {
  width: 50%; /* Ensure each pie chart takes about half of the container's width */
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  justify-content: center; /* Center the pie charts horizontally */
  max-width: 400px;
}

.progress-graph {
  width: 100%;
  text-align: center;
}
.comparison-card {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 1400px;
  margin: 0 auto;
  background-color: #f9f9f9;
  border-radius: 8px;
}
.comparison-card h2{
  margin: 0px;
}
.comparison-card h3{
  text-align: center;
  background-color: #23395d;
  color: #e0e0e0;
  border-radius: 8px;
}
.comparison-card h4{
  margin: 0px;
}

.goals-columns {
  display: flex;
  flex-direction: row; /* Ensure columns are side-by-side */
  justify-content: space-between;
  gap: 20px;
}

.goals-column {
  width: 48%; /* Each column takes roughly half the width */
}

.progress-line {
  position: relative;
  width: 100%;
  height: 7px;
  background-color: #e0e0e0; /* Light grey background */
  border-radius: 5px;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  text-align: right;
  color: #E0E0E0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  transition: width 0.5s ease-in-out; /* Smooth transition */
}

.fill-value-goals{
  font-size: xx-small;
}

.progress-labels {
  display: flex;
  justify-content: space-between;
  font-size: 0.9em;
  margin-top: 5px;
}
