.report-list-container {
    padding: 20px;
    background-color: #f5f5f5;
    text-align: center;
    font-family: sans-serif;
  }
  
  .cell {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ccc;
    padding: 5px;
    box-sizing: border-box;
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .header-cell {
    background-color: #23395d;
    color: white;
    font-weight: bold;
  }
  