
  .clients-search {
    display: flex;
    gap: 10px;
    margin: 10px 0;
  }
  
  .clients-search input,
  .clients-search select,
  .clients-search button {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ccc;
    height: 40px; /* Ensure all elements have the same height */
    width: max-content;
  }
  
  .clients-search button {
    background-color: #1a2a6c;
    color: white;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px;
  }
    
  
  .add_usr_close-btn {
    background: transparent;
    border: none;
    font-size: 20px;
    position: absolute;
    color: #333;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }

  .error-text{
    color: red;
    font-style: normal;
  }
  
  .add_usr_close-btn:hover {
    background-color: rgb(164, 191, 216);
  
  }
  .overlay-add-client{
    background-color: #23395d;;
    color:white ;
  }
  .overlay-add-client:hover{
    background-color: #14224a;
  }

  .overlay-delete-client{
    background-color: #b30000;
    color: white;
    margin-left: 20px;
  }

  .overlay-delete-client:hover{
    background-color: #8a0000;
  }
  