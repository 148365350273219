.calendar-page {
  padding: 20px;
}

.calendar-header {
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 10px;
}

.clients-header {
  padding: 10px 20px;
}

.event-add-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above everything else */
}

.overlay-content-calendar {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  position: relative;
}

.overlay-content-calendar input {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.overlay-content-calendar button {
  padding: 10px;
  cursor: pointer;
}

.close-btn_calendar {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  color: #333;
  font-size: 20px;
  cursor: pointer;
}
.close-btn_calendar:hover {
  background-color: rgb(164, 191, 216) !important;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.button-group button {
  padding: 10px 20px;
  margin: 0 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  font-size: 16px;
}

.button-group .delete-btn {
  background-color: #b30000; /* Dark red */
}
.button-group .delete-btn:hover {
  background-color: #860000; /* Darker red */
}

.button-group .update-btn {
  background-color: #23395d;
  color: white;
}
.button-group .update-btn:hover {
  background-color: #14224a;
  color: white;
}

.button-group button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.event-list {
  display: flex;
  flex-direction: column;
}

.event-item {
  margin-bottom: 20px;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

/* Remove default hover effect on calendar numbers */
.rbc-date-cell:hover {
  background-color: transparent !important;
}

/* Add hover effect on calendar squares */
.rbc-day-bg:hover {
  background-color: #f0f0f0 !important;
  cursor: pointer;
}

.rbc-button-link:hover {
  background-color: #f0f0f0 !important;
  cursor: pointer;
}

.calendar-page .rbc-calendar{
  height: 400px !important;
}