/* Container styles */
.optOut-container {
  padding: 20px;
  background-color: #f5f5f5;
  min-height: 90vh;
  font-family: Arial, sans-serif;
}

/* Header styles */
.optOut-header {
  background-color: #23395d;
  color: white;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  border-radius: 5px;
}

/* Table container styles */
.optOut-table-container {
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  max-height: calc(100vh - 160px);
  overflow: auto;
}

/* Table styles */
.optOut-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 10px;
}

.optOut-table th,
.optOut-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.optOut-table th {
  background-color: #23395d;
  color: white;
  position: sticky;
  top: 0;
}

.optOut-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.optOut-table tr:hover {
  background-color: #ddd;
}

/* Pagination styles */
.optOut-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0;
}

.optOut-pagination-button {
  padding: 8px 12px;
  margin: 0 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.optOut-pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.navigate-clients-btn {
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px 10px 0;
}