
.monitor-table-container {
  width: 100%;
  max-height: 67vh;/* El contenedor ocupará el 67% del alto de la pantalla */
  /* Adjust height to make space for header, footer, and other elements */
  overflow: auto;
  /* Allow both vertical and horizontal scrolling inside the table container */
  padding-top: 0px;
  box-sizing: border-box;
}

.monitor-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

/* Table headers and cells */
.monitor-table th,
.monitor-table td {
  border: 1px solid #ccc;
  padding: 10px 10px;
  text-align: left;
  white-space: nowrap;
  
  /* Prevent text wrapping */
}

.monitor_add_overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px; /* Reduce el margen superior para acercarlo al botón */
  z-index: 10000; /* Ensure overlay is on top of everything */

}

/* Style for table header */
.monitor-table th {
  background-color: #23395d;
  font-weight: bold;
  position: sticky;
  width: 100px; /* Definir el ancho fijo */
  padding: 5px; /* Opcional: Espaciado dentro del encabezado */
  top: 0;
  z-index: 2;

}

/* Improve button appearance */
.delete-monitor-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px; /* Ajusta el padding según sea necesario */
  margin-right: 10px; /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0; /* Elimina el margen superior */
  cursor: pointer;
  width: 100%; /* Ocupa todo el ancho del contenedor padre */
  border-radius: 5px; /* Bordes redondeados */
  display: block; /* Hace que el botón ocupe toda la línea */
}

.delete-monitor-btn:hover {
  background-color:#3a5d8c;
}

.add-monitor-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px; /* Ajusta el padding según sea necesario */
  margin-right: 10px; /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0; /* Elimina el margen superior si existe */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer;
  width: 10%; /* Porcentaje del contenedor padre */
  display: block; /* Asegúrate de que esté en la misma línea si es necesario */
}

.add-monitor-btn:hover {
  background-color:  #3a5d8c;
}
.overlay-add-monitor{
  background-color: #1a2a6c;
}
.overlay-add-monitor:hover{
  background-color: #14224a;
}

.overlay-delet-monitor{
  background-color: #1a2a6c;
}
.overlay-delet-monitor:hover{
  background-color: #de151f;
}

/* Make sure the content does not exceed the visible height */
.monitors-page {
  height: 100%;
  padding: 20px;
}

.monitor-model {
  max-width: 150px;
  /* Ajusta el ancho máximo para esta columna */
  overflow-x: auto;
  /* Permite el scroll horizontal si el contenido es demasiado largo */
  white-space: nowrap;
  /* Evita que el texto se rompa en múltiples líneas */
}

.monitor-Description {
  max-width: 300px;
  overflow-x: auto;
  white-space: nowrap;}