
.sidebar-icon {
  position: absolute;
  top: 3rem;               /* Changed from 40px to 2.5rem */
  left: 3rem;              /* Changed from 40px to 2.5rem */
  background-color: #e0e0e0;
  padding: 0.6rem;         /* Changed from 10px to 0.625rem */
  border-radius: 0.3125rem;  /* Changed from 5px to 0.3125rem */
  cursor: pointer;
  transition: background-color 0.3s;
  z-index: 1000;             /* Ensures the UserTab is above other elements */
}

.sidebar-icon:hover {
  background-color: #d0d0d0;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
  z-index: 999;              /* Ensures the overlay is above other elements */
}

.overlay.active {
  opacity: 1;
  visibility: visible;
}

.sidebar {
    position: fixed;
    top: 0;
    left: -20rem;              /* Changed from -300px to -20rem */
    width: 20rem;              /* Changed from 300px to 20rem */
    height: 100%;
    background-color: #fff;
    box-sizing: border-box;    /* Include padding and border in width */
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.3s;
    z-index: 1000;
    overflow-y: auto;          /* Added to make the sidebar scrollable */
}
.sidebar.open {
  left: 0;
}

.sidebar-header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;             /* Changed from 15px to 1rem */
}

.sidebar-logo {
  height: 15.625rem;         /* Changed from 250px to 15.625rem */
}

.close-btn {
  position: absolute;
  top: 1rem;                 /* Changed from 15px to 1rem */
  right: 1rem;               /* Changed from 15px to 1rem */
  background: none;
  border: none;
  font-size: 1.75rem;        /* Changed from 28px to 1.75rem */
  cursor: pointer;
  color: #333;
  padding: 0.3125rem;        /* Changed from 5px to 0.3125rem */
  transition: background-color 0.3s;
}

.close-btn:hover {
  background-color: rgb(164, 191, 216) !important;
}

.sidebar-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar-menu li {
  padding: 0.625rem 1.25rem; /* Changed from 10px 20px to 0.625rem 1.25rem */
}

.sidebar-menu li a {
  text-decoration: none;
  color: #333;
  display: block;
  transition: background-color 0.3s;
  padding: 0.3125rem;
  font-size: 1.375rem;       /* Changed from 22px to 1.375rem */
  font-weight: bold;
  width: 100%;
}

.sidebar-menu li a:hover {
  background-color: rgb(164, 191, 216) !important;
}


/* Mobile Styles */
@media (max-width: 480px) {
  .sidebar-icon {
      top: 2rem;             /* Reduced top position */
      left: 2rem;            /* Reduced left position */
      padding: 0.28rem;       /* Reduced padding */
      width: 2.3rem;       /* Reduced width */
      height: 2.3rem;       /* Reduced width */
  }
  .fabars-sidebar-icon{
    width: 1.7rem;
    height: 1.7rem;
  }

  .sidebar {
      width: 50%;            /* Sidebar takes 80% of the screen width */
      left: -80%;            /* Hidden position adjusted */
  }

  .sidebar.open {
      left: 0;
      width: 35vh;
  }

  .sidebar-logo {
      height: 9.375rem;      /* Reduced height to 150px equivalent */
  }

  .close-btn {
      font-size: 1.5rem;     /* Reduced font size */
      top: 0.75rem;          /* Adjusted top position */
      right: 0.75rem;        /* Adjusted right position */
  }

  .sidebar-menu li a {
      font-size: 1rem;       /* Reduced font size */
  }
}

body.sidebar-open {
  overflow: hidden !important;
}
