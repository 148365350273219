.menu-header {
  flex-wrap: wrap; /* Permite que los botones se ajusten en filas si es necesario */
  font-size: 11px;
  text-align: center;
  align-items: center; /* Centra verticalmente los botones */
  position: sticky;
  
}

.menu-header button {
  color: #ebebf2;
  background-color: #23395d;
  ;
  /* Fondo claro para el botón */
  border: none;
  padding: 8px 8px;
  margin: 2px;
  /* Espacio entre botones */
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  /* Suaviza el cambio de color */
}

.menu-header button:hover {
  background-color: #acc2e7;
  ;
  /* Fondo más oscuro al pasar el mouse */
}

.home-button {
  display: flex;
  flex-direction: column; /* Coloca el ícono encima del texto (si aplica) */
  align-items: center; /* Centra horizontalmente */
  justify-content: center; /* Centra verticalmente */
  background: transparent; /* Fondo transparente */
  border: none; /* Sin bordes */
  padding: 0; /* Sin padding extra */
  cursor: pointer; /* Cursor en forma de mano */
}

.home-button:hover {
  color: #0F2747; /* Cambia el color al pasar el mouse */
}

.icon-large {
  font-size: 2rem; /* Tamaño del ícono */
  margin-bottom: 0.5rem; /* Espacio entre el ícono y el texto */
}

.menu-header .home-button-transparent {
  background: none; /* Elimina el fondo */
  color: inherit; /* Usa el color del contenedor o especifica uno */
  border: none; /* Sin borde */
  padding: 0; /* Sin relleno */
  margin: 0; /* Sin margen */
  cursor: pointer; /* Cursor en forma de mano */
  display: flex; /* Centra el contenido del botón */
  align-items: center; 
  justify-content: center;
}

.menu-header .home-button-transparent .icon-large {
  font-size: 2rem; /* Tamaño del ícono */
  color: #23395d; /* Color del ícono */
  transition: color 0.3s ease; /* Animación suave al cambiar el color */
}

.menu-header .home-button-transparent:hover .icon-large {
  color: #0F2747; /* Cambia el color del ícono al pasar el mouse */
}



