.inventory {
  padding: 20px;
  position: relative;
}

/* Se usa en todas las page */


.header-row {
  display: flex;
  justify-content: flex-end;
  /* Alinea todos los elementos hacia la derecha */
  align-items: center;
  /* Alinea los elementos verticalmente */
  position: sticky;
  top: 0;
  z-index: 2;
  background-color: #fdfeff;
  color: rgb(14, 13, 13);
  padding: 10px;
  border-bottom: 0;
}

.header-row button {
  background: #23395d;
  ;
  border: none;
  color: #ebeef1;
  font-size: 16px;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  width: 35px;
  /* Ajusta este valor para darle más ancho */
  text-align: center;
  /* Asegura que el contenido esté centrado */
  transition: background-color 0.3s;
}

.header-row button:hover {
  background-color: #3a5d8c;
}

.header-title {
  flex-grow: 1;
  /* Hace que el título ocupe todo el espacio disponible */
  text-align: center;
  /* Centra el texto */
  margin: 0;
  /* Elimina el margen predeterminado de <h2> */
}

/* Se usa en todas las page */

.inventory-header {
  background-color: #23395d;
  color: white;
  position: sticky;
  padding: 10px 20px;
  z-index: 10;
  /* Keeps the header above other content */
  text-align: center;
  position: sticky;
  top: 0;
  width: 100%;
  /* Ensures the header takes up the full width */
  left: 0;
  /* Ensures the sticky element starts from the left edge */
  box-sizing: border-box;
  /* Includes padding in the element’s total width */

}

/* Se usa en todas las page */

.mouse-table-container {
  width: 100%;
  max-height: 67vh;
  /* El contenedor ocupará el 67% del alto de la pantalla */
  /* Adjust height to make space for header, footer, and other elements */
  overflow: auto;
  /* Allow both vertical and horizontal scrolling inside the table container */
  padding-top: 0px;
  box-sizing: border-box;
}

.placeholder-option {
  color: lightgray;
}

/* Se usa en todas las page */

.mouse-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.mouse-table th,
.mouse-table td {
  border: 1px solid #ccc;
  padding: 10px 10px;
  text-align: left;
  white-space: nowrap;
}


/* Style for table header */
.mouse-table th {
  background-color: #23395d;
  font-weight: bold;
  position: sticky;
  width: 100px;
  /* Definir el ancho fijo */
  padding: 5px;
  /* Opcional: Espaciado dentro del encabezado */
  top: 0;
  z-index: 2;

}

th {
  background-color: #23395d;
  color: white;
  position: relative;
  cursor: pointer;
}

/* Se usa en todas las page */

th:hover {
  background-color: #3a5d8c;
  /* Lighter blue */
}

/* Se usa en todas las page */

th .sort-arrow {
  margin-left: 5px;
  font-size: 0.8em;
}

/* Se usa en todas las page */

.edit-btn {
  background-color: #1a2a6c;
  color: white;
}

/* Se usa en todas las page */

.sort-ascending::after {
  content: '▲';
  position: absolute;
  right: 10px;
}

/* Se usa en todas las page */

.sort-descending::after {
  content: '▼';
  position: absolute;
  right: 10px;
}

/* Se usa en todas las page */

.add-mouse-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior si existe */
  border-radius: 5px;
  /* Bordes redondeados */
  cursor: pointer;
  width: 10%;
  /* Porcentaje del contenedor padre */
  display: block;
  /* Asegúrate de que esté en la misma línea si es necesario */

}

.delete-mouse-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior */
  cursor: pointer;
  width: 100%;
  /* Ocupa todo el ancho del contenedor padre */
  border-radius: 5px;
  /* Bordes redondeados */
  display: block;
  /* Hace que el botón ocupe toda la línea */
}

.delete-mouse-btn:hover {
  background-color: #3a5d8c;
}

.add-mouse-btn:hover {
  background-color: #3a5d8c;
}

.inventory-search {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  /* Asegura que el ancho del contenedor sea constante */
  max-width: 600px;
  /* O el tamaño que mejor se ajuste a tu diseño */
  flex-wrap: nowrap;
  /* Evita que los elementos se muevan a otra línea */
  margin-bottom: 10px;
  /* Espacio debajo del div */
}

/* Se usa en todas las page */

.inventory-search button {
  background-color: #23395d;
  color: white;
  min-width: 120px;
  /* Ancho mínimo para mantener el tamaño constante */
  padding: 5px 10px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  height: 45px;
  border-radius: 5px;
  /* Ajusta este valor para mayor redondez */
  transition: background-color 0.3s, transform 0.2s;
}

.inventory-search button:hover {
  background-color: #3a5d8c;

}

.inventory-search button:nth-child(2) {
  /* Aplica solo al botón "Show/Hide Passwords" */
  min-width: 160px;
  /* Ajusta según el tamaño del texto más largo */
}

.inventory-search button:active {
  background-color: #142254;
  transform: scale(0.98);
}


.inventory-search input {
  padding: 8px;
  font-size: 16px;
}

.inventory-search select {
  width: 200px;
  /* Ancho fijo */
  padding: 8px;
  font-size: 16px;
  box-sizing: border-box;
  /* Evita que el padding aumente el ancho total */
}

/* Se usa en todas las page */

.mouse_add_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  /* Reduce el margen superior para acercarlo al botón */
  z-index: 10000;
  /* Ensure overlay is on top of everything */
}

.overlay {
  position: fixed;
  /* Fija el overlay en la pantalla */
  top: 0;
  left: 0;
  width: 100vw;
  /* Ocupa el ancho completo de la pantalla */
  height: 100vh;
  /* Ocupa la altura completa de la pantalla */
  background-color: rgba(0, 0, 0, 0.5);
  /* Fondo semitransparente */
  display: flex;
  justify-content: center;
  /* Centra el contenido horizontalmente */
  align-items: center;
  /* Centra el contenido verticalmente */
  padding: 5%;
  /* Ajusta el padding en porcentaje para que sea responsivo */
  box-sizing: border-box;
  /* Incluye el padding dentro del tamaño total del overlay */
}

/* Se usa en todas las page */

.overlay-content-inventory {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 500px;
  max-width: 100%;
  max-height: 95%;
  margin-top: 3%;
  overflow-y: auto;
  overflow-x: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

/* Se usa en todas las page */


.overlay-content-inventory input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Se usa en todas las page */

.overlay-content-inventory select {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Se usa en todas las page */


.add_mouse_close-btn {

  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
  /* Empuja el botón hacia la derecha */

}

.add_mouse_close-btn:hover {
  background-color: rgb(164, 191, 216);

}

.overlay-add-mouse {
  background-color: #1a2a6c;
}

.overlay-add-mouse:hover {
  background-color: #14224a;
}

.overlay-delet-mouse {
  background-color: #1a2a6c;
}

.overlay-delet-mouse:hover {
  background-color: #d22121;
}

/* Se usa en todas las page */
input[type="date"].date-placeholder::before {
  color: #999;
  content: attr(placeholder);
}

input[type="date"].date-placeholder {
  color: #999;
}

input[type="date"].date-placeholder:focus::before,
input[type="date"].date-placeholder:valid::before {
  content: "";
}

input[type="date"].date-placeholder:focus,
input[type="date"].date-placeholder:valid {
  color: #000;
}

.error {
  color: rgb(199, 1, 1);
}

.Inventory_checkboxes {
  display: flex;
  flex-direction: row;
  grid-template-columns: auto;
  gap: 5px;
}

.mouse-page {

  height: 100%;
  /* Si los elementos están dentro de .mouse-page, el 100% del alto puede estar creando un ajuste justo */
  margin-top: 10px;
  /* Aumentar la separación entre .mouse-page y cualquier otro contenido anterior */
  padding: 20px;
}

.button-group {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
}

.button-group button:not(:last-child) {
  margin-right: 2px;
  /* Espacio reducido entre botones */
}


.column-filter-overlay {
  position: absolute;
  top: 125px;
  /* Adjust based on desired position */
  left: 600px;
  transform: translateX(-50%);
  padding: 5px;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 800;
  width: 200px;
}

.mouse-table th {
  cursor: pointer;
}

.searchBar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  /* Ajusta el margen inferior según lo necesites */
}