.admin-home-container {
    padding: 20px;
    text-align: center;
    background-color: #f8f9fa;
    border-radius: 8px;
    margin: 0 auto;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
.AdminDashboard-header{
    background-color: #23395d;
  color: white;
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 10px;
}

.admin-home-container h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.admin-home-container p {
    font-size: 1rem;
    margin-bottom: 30px;
    color: #555;
}

.admin-home-links button {
    margin: 10px;
    padding: 10px 20px;
    font-size: 1rem;
    color: white;
    background-color: #23395d;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.admin-home-links button:hover {
    background-color: #14224a;
    transition: background-color 0.3s;
}
