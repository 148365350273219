.call-list-container {
  padding: 20px;
}

.call-list-admin-funcs {
  margin-top: 10px;
  margin-bottom: 10px;
}

.call-list-admin-funcs select {
  width: 200px;
  height: 30px;
  margin-right: 10px;
}

.clickable-client-name {
  cursor: pointer;
  color: blue;
}

.clickable-client-name:hover {
  color: darkblue;
}

.delete-call-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 3px;
}

.delete-call-button:hover {
  background-color: darkred;
}

.call-list-container .call-list-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: left;
  z-index: 1000;
}

.call-list-container .call-list-overlay-content {
  background-color: #fff;
  padding: 20px;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  max-height: 80vh;
  overflow-y: auto;
}

.call-list-container .call-list-overlay-content h3 {
  margin-top: 0;
  font-size: 1.5em;
}

.call-list-container .call-list-overlay-content label {
  display: block;
  font-weight: bold;
  margin-top: 10px;
}

.call-list-container .call-list-overlay-content input,
.call-list-container .call-list-overlay-content select,
.call-list-container .call-list-overlay-content textarea {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1em;
}

.call-list-container .call-list-overlay-content button {
  margin-top: 10px;
  padding: 10px 15px;
  font-size: 1em;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.call-list-container .call-list-overlay-content button:first-of-type {
  background-color: #4CAF50;
  color: white;
}

.call-list-container .call-list-overlay-content button:last-of-type {
  background-color: #f44336;
  color: white;
  margin-left: 10px;
  white-space: nowrap;
}


.call-list-filter-control {
  width: 100%;
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
  gap: 10px;
  /* Space between select and input */
  /* Add spacing below the filter control */
  margin-left: auto;
  margin-right: auto;
}

.call-list-filter-control select,
.call-list-filter-control input
.call-list-filter-control button {
  height: 30px;
  /* Set consistent height for both */
  padding: 5px 10px;
  /* Add padding for a comfortable look */
  font-size: 1em;
  /* Standard font size */
  border: 1px solid #ccc;
  /* Light border */
  border-radius: 4px;
  /* Rounded corners */
  outline: none;
}

.call-list-filter-control select {
  width: 180px;
  /* Set a fixed width for the select dropdown */
}

.call-list-filter-control input {
  width: 180px;
}

.bulk-actions {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  width: 0%;
  margin-left: auto;
  margin-right: auto;
}

.bulk-actions select {
  width: 200px;
  height: 30px;
}

.bulk-actions button {
  min-width: 200px;
  width: 200px;
  height: 30px;
}

/* Tabs */
.call-list-tabs {
  display: flex;
  background-color: #f0f0f0;
  white-space: nowrap;
}

.call-list-tab {
  padding: 10px 15px;
  border: none;
  cursor: pointer;
  background-color: #f0f0f0;
  border-radius: 5px;
  white-space: nowrap;

}

.call-list-tab.active-tab {
  background-color: #007bff;
  color: white;
}

/* Filter Control */
.call-list-filter {
  margin-bottom: 20px;
}

.call-list-filter input {
  padding: 8px;
  width: 100%;
  max-width: 400px;
}
.round-radio {
  appearance: none; /* Remove default radio styling */
  width: 20px;
  height: 20px;
  border: 2px solid #045edc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.round-radio:checked {
  background-color: #0008ff;
}


.action-button-container {
  text-align: center;
  margin-top: 20px;
}

.action-button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  background-color: #23395d;
  color: white;
  border: none;
  border-radius: 5px;
  margin-right: 10px;
}

.round-radio {
  appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #045edc;
  border-radius: 50%;
  outline: none;
  cursor: pointer;
}

.round-radio:checked {
  background-color: #0008ff;
}

.grid-calls{
  height: 60vh !important;
  white-space: nowrap;
overflow: hidden;
}

.total-items-info h3{
  margin: 0%;
  font-size: 1em;
}

/* We assume your truncated text is displayed in .notes-cell */
.notes-cell {
  position: relative;             /* Needed for the tooltip positioning */
  max-width: 200px;              /* Cell width, or whatever you prefer */
  overflow: hidden;              /* Truncate overflow */
  white-space: nowrap;           /* Single line truncated */
  text-overflow: ellipsis;       /* "..." effect */
  cursor: pointer;               /* Indicate hover is interactive */
}

.notes-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.notes-modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.notes-modal-content h3 {
  margin-top: 0;
}

.notes-modal-content p {
  white-space: pre-wrap; /* Preserve line breaks */
}

.notes-modal-overlay .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}

.close-btn:hover {
  background: darkred;
}


@media (max-width: 500px) {
  .call-list-container .call-list-overlay-content {
    width: 90%;
    padding: 15px;
  }
}