.issue-container {
  width: 100%;
  max-width: 900px;
  margin: 30px auto;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.issue-header {
  text-align: center;
  margin-bottom: 20px;
}

.issue-header h1 {
  font-size: 24px;
  margin: 0;
  color: #333;
}

.issue-header p {
  font-size: 14px;
  color: #777;
}

.issue-section {

  padding-top: 5px;
  margin-bottom: 10px;
  align-items: center;
}

.issue-section h3 {
  font-size: 16px;
  margin-bottom: 10px;
  color: #333;
  border-bottom: 1px solid #ddd;
  padding-bottom: 5px;
}

.issue-section label {
  display: block;
  margin: 10px 0 5px;
  color: #333;
}

.issue-section li {
  display: block;
  margin: 10px 0 5px;
  color: #333;
}

.issue-section li input {
  width: 35%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.issue-section select {
  width: 35%;
}

.issue-section textarea {
  background-color: #f7f7f7;
  border-color: #ddd;
  width: 500px;
}

.issue-section .input-issue {
  flex-wrap: wrap;
  gap: 10px;
}

.issue-section input[type="date"] {
  width: 25%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.issue-section input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.issue-section button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #23395d;
  /* Color azul */
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.issue-section select {
  width: 300px;
  font-size: 14px;
  color: #101010;
}

.input-issue {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
  margin-bottom: 10px;
}

.input-issue label {
  width: 150px;
  font-size: 14px;
  color: #101010;
}

.input-issue input {
  width: 150px;
  font-size: 14px;
  color: #101010;
}

.input-issue select {
  width: 300px;
  font-size: 14px;
  color: #101010;
}

@media (max-width: 768px) {
  .input-issue {
    flex-direction: column;
    gap: 10px;
  }

  .input-issue label {
    width: 100%;
  }
}

/* Contenedor general del equipo asignado */
.assigned-equipment {
  margin-top: 10px;
}

/* Estilo de las listas de equipos */
.assigned-equipment ul {
  display: flex;
  /* Alinea los elementos en fila */
  flex-wrap: wrap;
  /* Permite que los elementos salten a la siguiente línea si no hay espacio */
  gap: 20px;
  /* Espacio entre cada checkbox */
  padding: 0;
  /* Elimina padding del ul */
  margin: 0;
  /* Elimina márgenes del ul */
  list-style: none;
  /* Elimina los puntos de la lista */
}

/* Estilo de cada elemento de lista */
.assigned-equipment li {
  display: flex;
  align-items: center;
}

/* Estilo del checkbox */
.assigned-equipment input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
}

/* Estilo del texto asociado */
.assigned-equipment label {
  /* font-weight: bold; Aplica negrita */
  font-size: 14px;
  /* Tamaño de letra ajustado */
  color: #333;
  /* Color del texto */
  cursor: pointer;
  /* Hace clickeable el texto asociado al checkbox */
  gap: 10px;
  /* Espacio entre checkbox y texto */
}


/* Contenedor principal para las listas */
.equipment-container {
  display: flex;
  /* Usa Flexbox */
  flex-wrap: wrap;
  /* Permite que los elementos pasen a otra línea */
  gap: 10px;
  /* Espacio entre columnas y filas */
}

/* Cada categoría de equipo (columna) */
.equipment-category {
  /**flex: 1 1 calc(33.3% - 5px); /* Ocupa el 33,3% del ancho menos el espacio */
  box-sizing: border-box;
  /* Incluye padding y borde en el ancho total */
  padding: 10px;
  border: 1px solid #ddd;
  /* Borde opcional */
  border-radius: 5px;
  background-color: #f9f9f9;
  /* Fondo sutil opcional */
  gap: 10px;
}

.previous-report {
  display: flex;
  justify-content: center;
  /* Centra horizontalmente */
  align-items: center;
  /* Centra verticalmente (si hay altura definida) */
  margin: 20px 0;
  /* Espaciado opcional */
  height: 100px;
  /* Ajusta la altura según necesidad */
}

.previous-report button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  background-color: #23395d;
  /* Color azul */
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

/* Espaciado entre los elementos de la lista */
.equipment-category ul {
  list-style: none;
  /* Elimina el estilo de lista predeterminado */
  padding: 0;
}

.equipment-category li {
  margin-bottom: 8px;
  /* Espacio entre elementos de la lista */
}

/* Estilo de checkbox y texto */
.equipment-item {
  display: flex;
  align-items: center;
  /* Centra verticalmente el checkbox y el texto */
  gap: 10px;
  /* Espacio entre checkbox y texto */
  padding: 5px;
  /* Padding fijo para evitar cambios visuales */
}

.software-item {
  margin-bottom: 10px;
  /* Espacio entre filas */
}

.software-row {
  display: flex;
  align-items: center;
  /* Alinea verticalmente el select y el input */
  gap: 10px;
  /* Espacio entre el select y el input */
}

.software-row select {
  min-width: 200px;
  /* Ancho mínimo del select */
}

.software-row input {
  flex: 1;
  /* El input ocupará el espacio restante */
  padding: 5px;
  /* Espaciado interno del input */
  min-width: 150px;
  /* Ancho mínimo del input */
}

.issue-section textarea.long-text {
  width: 100%; /* Ensure it takes up the full width of its container */
  max-width: 700px; /* Set the maximum width */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 100px; /* Initial height */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  resize: none; /* Prevent manual resizing by the user */
  overflow:scroll; /* Hide overflow */
  min-height: 100px;
}


