.edit-overlayEdit {
    position: fixed;
    top:0;
    left: 0;
    width: 100%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Fondo semitransparente */
    display: flex;
    justify-content: center;
    padding: 20px;
    z-index: 1000;
    overflow: hidden; /* Elimina el scroll interno del overlay */
}

.edit-overlayEdit div {
    padding: 20px;
}

.overlayEdit-content {
    background: #fbf8f8;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    max-width: 400px;
    width: 85%;
    position: relative;
    overflow-y: auto;
    max-height: 87vh;
    /* Limita la altura máxima */
    margin: 300px;
    /* Agrega un margen uniforme alrededor */
}

.overlayEdit-content h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: #000;
}


.overlayEdit-content label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
}

.overlayEdit-content input,
.overlayEdit-content select {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

.overlayEdit-content input:focus,
.overlayEdit-content select:focus {
    outline: none;
    border-color: #23395d;
    ;
    /* Cambia el borde al enfocarse */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.overlayEdit-content .button-group {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
}

.overlayEdit-content button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.overlayEdit-content button[type="submit"] {
    background-color: #23395d;
    color: #fff;
}

.overlayEdit-content button[type="submit"]:hover {
    background-color: #4d657e;
}

.overlayEdit-content button[type="button"] {
    background-color: #23395d;
    color:white;
    border: 1px solid #ccc;
}

.overlayEdit-content button[type="button"]:hover {
    background-color: #4d657e;
}


