.principal-container {
  display: flex;
  flex-direction: column; /* Coloca el h1 y el logo en columna */
  align-items: center; /* Centra los elementos horizontalmente */
  text-align: center; /* Centra el texto dentro del header */
  gap: 10px; /* Espaciado entre el h1 y el logo */
  width: 30%; /* Asegura que ocupe todo el ancho disponible */

}
/* Título principal */
.principal-container h1 {
  font-size:40px; /* Ajusta el tamaño del título */
  margin: 0; /* Elimina los márgenes predeterminados */
  color: #14224a; /* Color del texto */
  font-weight: bold; /* Hace el texto más destacado */
}
.principal-box {
  display: flex; /* Activa Flexbox */
  flex-direction: row; /* Coloca los elementos en fila */
  justify-content: center; /* Centra horizontalmente el contenido */
  align-items: flex-start; /* Alinea verticalmente los elementos al inicio */
  gap: 20px; /* Espaciado entre las secciones */
  flex-wrap: wrap; /* Permite que los elementos bajen si no caben */
  width: 30%; /* Asegura que ocupe todo el ancho disponible */
  box-sizing: border-box; /* Incluye padding y borde en el tamaño */
}

.datePicker {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dp {
  margin-bottom: 10px;
  /* Ajusta el espaciado entre los campos de fecha */
}

.logo-container {
  width: 50%;
  /* Ocupa el 100% del contenedor */
  max-width: 500px;
  /* Establece un ancho máximo para la imagen */
  height: auto;
  /* Mantiene la proporción de la imagen */
  display: flex;
  margin: 0 auto;
  /* Centra la imagen dentro del contenedor */
  text-align: center;

}
.logo-container logo {
  max-width: 200%;
  height: 120px;
  padding: 0;
}

.principal-form {
  width: 100%;
  /* Ensure the form is the same width as other boxes */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px
}
.principal-form h2 {
  margin: 0; /* Elimina los márgenes predeterminados */
  padding: 10px; /* Asegura que no haya espacio adicional */
  color: #14224a;
}

.form-section {
  width: 100%;
  /* Ensure all boxes have the same width */
  max-width: 700px;
  /* Set a maximum width */
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-section label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.select-box {
  background-color: #fff;
  border: 1px solid #ccc;
  margin: 0; /* Elimina cualquier margen adicional */
  border-radius: 4px;
  padding: 5px;
  font-size: 14px;
  width: auto;
  /* Ajusta el ancho al contenido */
  flex: 1;
  /* Permite que el select ocupe el espacio disponible */
}


.form-section input,
.form-section select {
  width: auto;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-section img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.one_to_one {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain the aspect ratio */
}

.radio-group {
  justify-content: center;
  gap: 20px;
}

.radio-group label {
  font-weight: normal;
}

.add-inventory-btn {
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
  /* Este padding ajusta el espacio dentro del botón */
  cursor: pointer;
  border-radius: 5px;
  display: inline-block;
  /* Hace que el botón se ajuste a su contenido */
  width: auto;
  /* Ajusta el ancho del botón al contenido */
  text-align: center;
  /* Centra el texto dentro del botón */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
}


.employee-content {
  display: flex;
  flex-direction: row; /* Organiza los botones en columna */
  gap: 10px;
  /* Reduce el espacio entre los botones */
  margin-left: 10%;
  /* Espacio superior opcional */
}

.employee-header {
  display: flex;
  flex-direction: column;
  gap: 5px; /* Controla el espacio entre el h2 y el div */
  align-items: center;
  /* Esto centrará los elementos */
}
.employee-header h2 {
  margin: 0; /* Elimina los márgenes predeterminados */
  padding: 4; /* Asegura que no haya espacio adicional */
}


button[type="submit"] {
  padding: 10px 20px;
  background-color: #1a2a6c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #14224a;
}


.flyer-section {
  position: relative;
}

.flyer-header {
  background-color: #1a2a6c;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: left;
  font-weight: bold;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: -20px;
  margin-bottom: 10px;
}

.extra_info_label {
  font-weight: normal;
}

.checkbox-group {
  display: block;
}

.checkbox-group label {
  font-weight: normal;
}


.required-asterisk::after {
  content: '*';
  color: red;
  margin-left: 5px;
}

.form-section input.long-text {
  width: 100%;
  /* Ensure it takes up the full width of its container */
  max-width: 700px;
  /* Set the maximum width */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 100px;
  /* Initial height */
}

.form-section textarea.long-text {
  width: 100%;
  /* Ensure it takes up the full width of its container */
  max-width: 700px;
  /* Set the maximum width */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 100px;
  /* Initial height */
  box-sizing: border-box;
  /* Ensure padding and border are included in the element's total width and height */
  resize: none;
  /* Prevent manual resizing by the user */
  overflow: scroll;
  /* Hide overflow */
  min-height: 150px;
}

.navigation-buttons button {
  background-color: #1a2a6c;
  margin: 10px;
}

.navigation-buttons button:hover {
  background-color: #14224a;
  margin: 10px;
}

input:invalid {
  border-color: red;
}

.validation-message {
  color: red;
}

.general-container {
  display: flex; /* Activa Flexbox */
  flex-direction: column; /* Coloca los elementos en columna */
  align-items: center; /* Alinea las secciones verticalmente al inicio */
  gap: 20px; /* Espacio entre las secciones */
  margin: 20px; /* Margen alrededor del contenedor */
  padding: 20px; /* Espaciado interno general */
  width: 100%; /* Asegura que ocupe todo el ancho disponible */
  box-sizing: border-box; /* Incluye padding y borde en el tamaño */
}

/* Estilo para cada sección */
.general-container section {
  display: flex;
  flex-direction: column; /* Mantiene los elementos dentro de cada sección en columna */
  align-items: center; /* Centra los elementos dentro de cada sección */
  gap: 10px; /* Espacio entre los elementos dentro de una sección */
  padding: 10px; /* Espaciado interno */
  border: 2px solid  #14224a;; /* Borde opcional */
  border-radius: 8px; /* Bordes redondeados */
  background-color: #d4dce3; /* Fondo claro */
}

.general-container section button {
  display: inline-block; /* Asegura que los botones respeten el contenido */
  padding: 10px 20px; /* Espaciado interno */
  margin: 10px; /* Espaciado externo entre botones */
  font-size: 14px; /* Tamaño de fuente */
  font-weight: bold; /* Hace el texto más destacado */
  color: #ffffff; /* Color del texto */
  background-color: #23395d; /* Color de fondo */
  border: none; /* Sin bordes */
  border-radius: 6px; /* Bordes redondeados */
  cursor: pointer; /* Cambia el cursor al pasar el mouse */
  transition: background-color 0.3s ease, transform 0.2s ease; /* Suaviza efectos */
}

.general-container section button:hover {
  background-color: #1A2C48; /* Fondo más oscuro al pasar el mouse */
  transform: scale(1.05); /* Aumenta ligeramente el tamaño */
}

.general-container section button:active {
  background-color: #14203a; /* Fondo aún más oscuro al hacer clic */
  transform: scale(0.95); /* Reduce ligeramente el tamaño */
}

.general-container section button:disabled {
  background-color: #b0b0b0; /* Fondo gris para botones desactivados */
  cursor: not-allowed; /* Cambia el cursor a no permitido */
  color: #ffffff; /* Asegura que el texto sea legible */
}

