/* Loading overlay to cover the whole screen */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.7);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  /* CSS Spinner */
  .loading-spinner {
    border: 8px solid rgba(255, 255, 255, 0.3); /* Light border */
    border-top: 8px solid white; /* Darker border for the spinner effect */
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: spin 1.5s linear infinite; /* Animation for spinning */
  }
  
  /* Spinner animation */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  