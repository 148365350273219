/* src/index.css */
body {
  font-family: 'Roboto', sans-serif !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Optional: Apply different weights to headings and paragraphs */
h1, h2, h3, h4, h5, h6 {
  font-weight: 700; /* Bold for headings */
}

p {
  font-weight: 400; /* Regular for paragraphs */
}

/* input, select, textarea {
  font-size: 14px !important; 
  padding: 8px; 
} */