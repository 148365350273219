.reports button{
  padding: 10px 10px;
  background-color: #1a2a6c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.scrollable-container {
  overflow-x: auto;
  overflow-y: auto;
  max-width: 100%;
  max-height: 69vh; /* Adjust the height as needed */
}

.sort-ascending::after {
  content: '▲';
}

.sort-descending::after {
  content: '▼';
}

.reports {
  padding: 20px 20px 0 20px; /* top right bottom left */
}

.reports-search {
  display: flex;
  margin-bottom: 10px;
}

.reports-search input {
  padding: 2px;
  margin-left: 10px;
  margin-right: 10px;
  max-width: 30vw;
}

.reports-search select {
  padding: 10px;
  height: 40px;
  max-width: 10vw;
}

.reports-header {
  background-color: #23395d; /* Dark blue */
  color: white;
  padding: 10px 20px;
  text-align: center;
  display: block;
  margin-bottom: 10px;
}

.back-reports-button {
  background-color: #1a2a6c; /* Lighter blue */
  height: 40px;
  color: white; /* Ensure the button text is readable */
}

.back-reports-button:hover {
  background-color: #14224a; /* Darker blue on hover */
  margin-right: 10px;
}

.excel-button {
  background-color: #1a2a6c; /* Lighter blue */
  margin-left: 10px;
  max-height: 40px;
  width: 200px;
  color: white; /* Ensure text is visible */
}

.excel-button:hover {
  background-color: #14224a; /* Darker blue on hover */
}

.grid-cell {
  overflow: hidden;
  text-overflow: ellipsis; /* Add the ellipsis (...) */
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: left;
}

.grid-cell:hover {
  background-color: #616361 !important;
  cursor: pointer;
}

.grid-reports{
  width: 100% !important;
  height: 60vh !important;
}

.header-cell{
  height: 60px;
}


.TotalReports{
  margin: 0px;
}
