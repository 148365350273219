/* ===== Overlay Styling ===== */
.template-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Prevent page scrolling */
}

/* ===== Modal Box Styling ===== */
.template-modal {
    display: flex;
    flex-direction: row; /* Arrange children horizontally */
    gap: 20px; /* Space between form and template list */
    background-color: white;
    width: 600px; /* Increase width for the side-by-side layout */
    max-width: 90%;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}
.template-form {
    flex: 1; /* Allow the form to grow and take available space */
}

/* ===== Form Styling ===== */
.template-form label {
    display: block;
    margin: 10px 0 5px;
    font-weight: bold;
    text-align: left;
}

.template-form input,
.template-form textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

/* ===== Buttons ===== */
.add-template-button,
.close-template-button {
    background-color: #1a2a6c; /* Blue background */
    color: #ffffff;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    margin-top: 10px;
    transition: background-color 0.3s ease;
}

.add-template-button:hover {
    background-color: #14224a; /* Darker blue */
}

.close-template-button {
    background-color: #f44336; /* Red background */
    margin-top: 20px;
}

.close-template-button:hover {
    background-color: #d32f2f; /* Darker red */
}

/* ===== Template List Styling ===== */
.template-list {
    flex: 1; /* Allow the template list to grow and take available space */
    overflow-y: auto; /* Scroll if content exceeds height */
    max-height: 400px; /* Limit the height for better layout */
}

.template-list ul {
    list-style: none; /* Remove bullets */
    padding: 0;
    margin: 0;
}

.template-list h4 {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: bold;
}

.template-item {
    background-color: #f9f9f9;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    display: flex;
    justify-content: space-between; /* Space out name and buttons */
    align-items: center;
}

/* ===== Day Shift Items ===== */
.day-shift-item {
    display: grid;
    grid-template-columns: 1fr auto auto auto; /* Label, checkbox, time pickers */
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.day-shift-item label {
    text-align: left;
    font-weight: bold;
}

.day-shift-item input[type="checkbox"] {
    justify-self: end;
    margin: 0;
}

.day-shift-item input[type="time"] {
    width: 100px;
    padding: 5px;
    font-size: 14px;
}

/* ===== Responsive Design ===== */
@media (max-width: 768px) {
    .template-modal {
        width: 90%;
        padding: 15px;
    }
    .day-shift-item {
        grid-template-columns: 1fr auto auto; /* Reduce columns for smaller screens */
        gap: 5px;
    }
}

/* ===== Utility Classes ===== */
