.laptop-page {
  height: 100%;
  padding: 20px;
}

.laptop-table-container {
  width: 100%;
  max-height: 67vh;
  /* El contenedor ocupará el 67% del alto de la pantalla */
  /* Adjust height to make space for header, footer, and other elements */
  overflow: auto;
  /* Allow both vertical and horizontal scrolling inside the table container */
  padding-top: 0px;
  box-sizing: border-box;
}

.laptop-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  /* Establece un ancho fijo para las columnas */
}

.laptop-table th,
.laptop-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 150px;
  table-layout: auto;
  /* Permite que las columnas se ajusten al contenido */
  padding: 8px 10px;
  /* Reduce el padding dentro de las celdas */
  margin: 0;
  /* Asegura que no haya márgenes adicionales */
}

.laptop-table th {
  background-color: #23395d;
  font-weight: bold;
  position: sticky;
  width: 100px;
  /* Definir el ancho fijo */
  padding: 5px;
  /* Opcional: Espaciado dentro del encabezado */
  top: 0;
  z-index: 2;
}

.laptop-table th:nth-child(5),
.laptop-table th:nth-child(20),
.laptop-table th:nth-child(21),
.laptop-table th:nth-child(22),
.laptop-table th:nth-child(23) {
  width: 250px;
  /* Ancho específico para estas columnas */
}

.laptop-table th:nth-child(8),
.laptop-table th:nth-child(11),
.laptop-table th:nth-child(12),
.laptop-table th:nth-child(14),
.laptop-table th:nth-child(24),
.laptop-table th:nth-child(25),
.laptop-table th:nth-child(29) {
  width: 150px;
  /* Ancho específico para estas columnas */
}

.add-laptop-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior si existe */
  border-radius: 5px;
  /* Bordes redondeados */
  cursor: pointer;
  width: 12%;
  /* Porcentaje del contenedor padre */
  display: block;
  /* Asegúrate de que esté en la misma línea si es necesario */
}

.add-laptop-btn:hover {
  background-color: #3a5d8c;
}


.delete-laptop-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior */
  cursor: pointer;
  width: 100%;
  /* Ocupa todo el ancho del contenedor padre */
  border-radius: 5px;
  /* Bordes redondeados */
  display: block;
  /* Hace que el botón ocupe toda la línea */
}

.delete-laptop-btn:hover {
  background-color: #3a5d8c;
}

.laptop_add_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  /* Reduce el margen superior para acercarlo al botón */
  z-index: 10000;
  /* Ensure overlay is on top of everything */
}


.add_laptop_close-btn {

  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
  /* Empuja el botón hacia la derecha */

}

.add_laptop_close-btn:hover {
  background-color: rgb(164, 191, 216);

}

.overlay-add-laptop {
  background-color: #23395d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  /* Reducido el padding vertical para disminuir la altura */
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;
  /* Ajusta el ancho al contenido del texto */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
}

.overlay-add-laptop:hover {
  background-color: #14224a;
}

.overlay-delet-laptop {
  background-color: #23395d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 5px;
  /* Reducido el padding vertical para disminuir la altura */
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto;
  /* Ajusta el ancho al contenido del texto */
  white-space: nowrap;
  /* Evita que el texto se divida en varias líneas */
}

.overlay-delet-laptop:hover {
  background-color: #d22121;

}

.laptop-Description {
  max-width: 300px;
  /* Ajusta el ancho máximo para esta columna */
  overflow-x: auto;
  /* Permite el scroll horizontal si el contenido es demasiado largo */
  white-space: nowrap;
  /* Evita que el texto se rompa en múltiples líneas */
}

.laptop-bitloker {
  max-width: 200px;
  overflow-x: auto;
  white-space: nowrap;
  overflow-x: auto;
  padding: 10px;
  box-sizing: border-box;
}