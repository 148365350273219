.appointments-page {
  width: 100%;
  position: relative;
  padding: 20px;
}
.appointments-page h2{
  margin: 5px;
}

.appointments-header {
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
  text-align: center;
}

.appointments-controls {
  display: flex;
  align-items: center; /* Add this line */
  gap: 10px;
  margin: 20px 0;
}

.appointments-controls input,
.appointments-controls select,
.appointments-controls button {
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 44px; /* Ensure all elements have the same height */
  width: max-content;
}


.appointments-controls button {
  background-color: #1a2a6c;
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}

.add-appointment-btn {
  margin-top: 0;
  background-color: #1a2a6c;
  color: white;
  height: fit-content;
  height: 46px;
}

.add-appointment-btn:hover {
  background-color: #14224a;
}

.status-created {
  overflow: hidden;
  text-overflow: ellipsis; /* Add the ellipsis (...) */
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  justify-content: left;
}

.status-created:hover {
  background-color: #616361 !important;
  cursor: pointer;
}


.event-add-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it is above everything else */
  padding: 20px;
  overflow-y: auto;
  overflow-x: auto;
}

.overlay-content-appointments {
  background: white;
  padding: 20px;
  border-radius: 10px;
  height: 90vh;
  min-width: 780px;
  min-height: 500px;
  position: relative;
  overflow-y: auto;
}

.overlay-content-appointments input {
  padding: 2px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.close-btn_appointments {
  background: transparent;
  border: none;
  font-size: 20px;
  position: absolute;
  color: #333;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.close-btn_appointments:hover {
  background-color: rgb(164, 191, 216) !important;
}
.button-group {
  display: flex;
  justify-content: space-between;
  margin-top: 0px !important;
}

.add-btn,
.delete-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.add-btn {
  background-color: #1a2a6c;
  color: white;
}

.add-btn:hover {
  background-color: #14224a;
}

.delete-btn {
  background-color: #1a2a6c;
  color: white;
}

.delete-btn:hover {
  background-color: #14224a;
}

.edit-btn {
  background-color: #1a2a6c;
  color: white;
}

.edit-btn:hover {
  background-color: #14224a;
}

.notes-input {
  width: 100%;
  max-width: 100%;
  height: auto;
  min-height: 100px; /* You can adjust the minimum height */
  resize: vertical;
}

.appointment-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px; /* Increased gap for better spacing */
}
.full-width {
  grid-column: 1 / -1; /* Span across both columns */
  position: relative;
}
.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.form-group select,
.form-group textarea{
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  height: 38px;
}
.form-group input[type="time"] {
  height: 38px !important;
}

/* Notes field adjustment */
.notes-input {
  min-height: 100px;
  resize: vertical;
}
.form-group-row {
  display: flex;
  justify-content: space-between;
  width:100%;
}

.form-group select {
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin: 0px;
}

/* Label styling */
.form-group label {
  display: block;
  font-weight: bold;
}

.client-suggestions {
  max-height: 150px; /* Set a fixed height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: white; /* Set background color */
  border: 1px solid #ccc; /* Add a border to the dropdown */
  border-radius: 4px; /* Round the corners */
  list-style-type: none; /* Remove default list styling */
  padding: 5px; /* Add padding */
  margin-top: 5px; /* Add some margin to the top */
  position: absolute; /* Absolute positioning */
  width: calc(100% - 10px); /* Full width of the input box minus padding */
  z-index: 10; /* Ensure it appears above other elements */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Styles for each client suggestion item */
.client-suggestions li {
  padding: 5px; /* Add padding to each item */
  cursor: pointer; /* Change cursor on hover */
}

/* Highlight item on hover */
.client-suggestions li:hover {
  background-color: #f0f0f0; /* Highlight color */
}

.add-client-btn {
  background-color: #1a2a6c;
  color: white; /* White text */
  border: none; /* Remove border */
  border-radius: 50%; /* Make it a circle */
  width: 30px; /* Width of the button */
  height: 30px; /* Height of the button */
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px; /* Font size for the "+" */
  cursor: pointer; /* Cursor pointer on hover */
  margin-left: 5px; /* Space between the input and button */
}

/* Add a hover effect */
.add-client-btn:hover {
  background-color: #14224a;
}


.client-form-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's on top of other elements */
}

.overlay-content-client {
  position: relative;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 600px; /* Adjust as needed */
  max-height: 90%;
  overflow-y: auto; /* Scroll if content is too tall */
}

.close-btn_client {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

/* Appointment status colors */
.status-created {
  background-color: rgba(255, 255, 15, 0.3) !important; /* Yellowish with some opacity */
}

.status-confirmed {
  background-color: rgba(0, 0, 255, 0.2) !important; /* Blueish with some opacity */
}

.status-canceled {
  background-color: rgba(255, 0, 0, 0.2) !important; /* Redish with some opacity */
}