.qhpByNavigator-container{
    overflow-x: auto;
}
.qhpByNavigator-table{
    position: relative;
    overflow-x: auto;
    width: 100%;
}

.qhpByNavigator-table th:first-child{
    position: sticky;
    left: 0;
    z-index: 100;
}

.qhpByNavigator-table td:first-child {
    position: sticky;
    left: 0;
    background-color: inherit;
    z-index: 100;
}