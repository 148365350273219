/* Parent Container & Header */
.WorkSchedules {
  width: 100%;
  height: 100%;
  padding: 20px;
}

.WorkSchedules-header {
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 10px;
  width: 100%;
}

/* ---------------------------------------
   CALENDAR WRAPPER
---------------------------------------- */
.ws-work-schedules-wrapper {
  width: 100%;
  height: 90vh;
  /* Use most of viewport height */
  position: relative;
  background-color: #f7f7f7;
  overflow: hidden;
  /* Prevent unnecessary scrolling */
}

/* ---------------------------------------
   REACT BIG CALENDAR (RBC) STYLES
---------------------------------------- */
.ws-work-schedules-calendar {
  background-color: #f5f7fa;
  /* Light gray-blue background */
  width: 100%;
  height: 100%;
  min-height: 500px;
  border-radius: 8px;
}

/* RBC grid lines (Time Content) */
.ws-work-schedules-calendar .rbc-time-content>*+*>* {
  border-color: #cbd5e1 !important;
  /* Light gray lines */
}

/* Time slot row height (makes rows more compact) */
.rbc-time-slot {
  height: 20px !important;
}

/* Horizontal line under each timeslot group */
.rbc-timeslot-group {
  border-bottom: 1px solid #d1d5db !important;
  height: 5px !important;
  min-height: 10px !important;
}

/* Time column background */
.rbc-time-column {
  background-color: #ffffff;
}

/* Time labels */
.rbc-timeslot-group .rbc-label {
  font-size: 12px;
  color: #6b7280;
  /* Grayish text */
}

/* Current time indicator (red line) */
.rbc-current-time-indicator {
  background-color: #ff3b30 !important;
  height: 2px !important;
}

/* Make events look nicer */
.rbc-event {
  border-radius: 8px;
  padding: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

/* Hide RBC’s vertical scrollbars */
.rbc-time-content {
  overflow-y: hidden !important;
}

/* Stripe effect in Day/Week/Month views */
.ws-work-schedules-calendar .rbc-day-bg:nth-child(odd) {
  background-color: #ffffff;
}

.ws-work-schedules-calendar .rbc-day-bg:nth-child(even) {
  background-color: #f0f0f0;
}

/* Hover pop-up effect on day cells */
.ws-work-schedules-calendar .rbc-day-bg:hover {
  transform: scale(1.03);
  z-index: 10;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #8e8e8e !important;
  cursor: pointer;
}

/* Bold the toolbar label (date range) */
.ws-work-schedules-calendar .rbc-toolbar-label {
  font-weight: bolder;
}

/* ---------------------------------------
   TOOLBAR (Above Calendar)
---------------------------------------- */
.ws-toolbar {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ddd;
}

.ws-toolbar button {
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  height: 20px;
  margin-top: 20px;
  background-color: #1a2a6c;
  color: white;
}

.ws-toolbar button:hover {
  background-color: #23395d;
}

/* ---------------------------------------
   SELECTORS (Location/User)
---------------------------------------- */
.ws-selectors {
  display: flex;
  align-items: center;
  gap: 20px;
  /* space between selectors */
}

.ws-selector {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* space between label and select */
}

.ws-selector label {
  font-size: 14px;
  font-weight: bold;
}

.ws-selector select {
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  cursor: pointer;
  width: fit-content;
  height: 20px;
}

@media (max-width: 480px) {
  .ws-toolbar {
    flex-direction: column; /* Stack items vertically */
    gap: 5px; /* Reduce space between elements */
    padding: 5px; /* Reduce padding */
  }

  .ws-toolbar button {
    width: 100%; /* Make buttons take full width */
    max-width: 150px; /* Set a max width for buttons */
    height: 30px;
    font-size: 12px;
    margin-top: 0px;
  }

  .ws-selectors {
    flex-direction: column; /* Stack selectors */
    gap: 5px; /* Reduce space */
    width: 100%;
  }

  .ws-selector select {
    width: 100%; /* Full width on smaller screens */
    max-width: 200px;
    height: 30px;
    font-size: 12px;
  }

}