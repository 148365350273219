.footer {
    background-color: #f8f9fa;          /* Light gray background */
    padding: .4rem;                   /* Responsive padding */
    border-top: 1px solid #dee2e6;      /* Thin border at the top */
    text-align: center;                 /* Center-align all footer content */
    box-sizing: border-box;             /* Include padding in total width */
    width: 100%;                        /* Full width */
}

.footer-content {
    max-width: 1200px;                  /* Limit content width on large screens */
    margin: 0 auto;                     /* Center content */
    padding: 0 1rem;                    /* Horizontal padding */
}

.cms-disclaimer {
    font-size: 0.875rem;                /* Slightly smaller font size */
    color: #6c757d;                     /* Muted text color */
    line-height: 1.5;                   /* Improve readability */
    margin: 0;                          /* Remove default margin */
}

.cms-disclaimer p {
    margin: 0;                          /* Remove default margin */
}

/* Mobile Styles */
@media (max-width: 576px) {
    .footer {
        padding: 1rem;                  /* Reduce padding on mobile */
    }

    .footer-content {
        padding: 0 0.5rem;              /* Reduce horizontal padding */
    }

    .cms-disclaimer {
        font-size: 0.75rem;             /* Smaller font size on mobile */
    }
}
