.reports-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
}
.reports-container button {
  padding: 10px 10px;
  background-color: #1a2a6c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.report-box {
  background-color: #fff;
  width: 100%;
  /* Ensure all boxes have the same width */
  max-width: 700px;
  /* Set a maximum width */
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo {
  max-width: 25%;
  height: auto;
  padding: 0;
}

.report-form {
  width: 100%;
  /* Ensure the form is the same width as other boxes */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-section {
  width: 100%;
  /* Ensure all boxes have the same width */
  max-width: 700px;
  /* Set a maximum width */
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.form-section label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
}

.form-section input,
.form-section select {
  width: auto;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.form-section img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.one_to_one {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
  /* Adjust the width as needed */
  height: auto;
  /* Maintain the aspect ratio */
}

.radio-group {
  justify-content: center;
  gap: 20px;
}

.radio-group label {
  font-weight: normal;
}
.check-group {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next row */
  gap: 10px; /* Adds space between checkboxes */
}

.check-group label {
  flex: 1 1 calc(50% - 10px); /* Each checkbox takes up 50% of the width minus the gap */
  box-sizing: border-box; /* Ensures padding/border is included in the width */
  display: flex;
  align-items: center;
  gap: 5px; /* Adds space between the checkbox and the label text */
}

.check-group input[type="checkbox"] {
  margin-right: 5px; /* Optional: Add spacing between the checkbox and label text */
}


button[type="submit"] {
  padding: 10px 20px;
  background-color: #1a2a6c;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #14224a;
}


.flyer-section {
  position: relative;
}

.flyer-header {
  background-color: #1a2a6c;
  color: white;
  padding: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  text-align: left;
  font-weight: bold;
  width: calc(100% + 40px);
  margin-left: -20px;
  margin-top: -20px;
  margin-bottom: 10px;
}

.extra_info_label {
  font-weight: normal;
}

.checkbox-group{
  display: block;
}
.checkbox-group label {
  font-weight: normal;
}


.required-asterisk::after {
  content: '*';
  color: red;
  margin-left: 5px;
}

.form-section input.long-text {
  width: 100%;
  /* Ensure it takes up the full width of its container */
  max-width: 700px;
  /* Set the maximum width */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 100px; /* Initial height */
}
.form-section textarea.long-text {
  width: 100%; /* Ensure it takes up the full width of its container */
  max-width: 700px; /* Set the maximum width */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 100px; /* Initial height */
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
  resize: none; /* Prevent manual resizing by the user */
  overflow:scroll; /* Hide overflow */
  min-height: 150px;
}

.navigation-buttons button {
  background-color: #1a2a6c;
  margin: 10px;
}

.navigation-buttons button:hover {
  background-color: #14224a;
  margin: 10px;
}

input:invalid {
  border-color: red;
}
.validation-message{
  color: red;
}

.list-reports-button{
  background-color:#1a2a6c;
}
.list-reports-button:hover{
  background-color:#14224a;
}

.reports-header {
  width: 100%;
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
  text-align: center;
}
.buttons-container-reports {
  width: 100%;
  position: sticky;
  top: 0; /* Keeps the container stuck to the top of the viewport */
  background-color:transparent; /* Background matches the container */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  z-index: 10; /* To ensure the container stays above other elements */
}

.save-progress-button, .release-button {
  background-color: #1a2a6c;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  transition: background-color 0.3s;
}

.save-progress-button:hover, .release-button:hover {
  background-color: #14224a;
}

.client-suggestions {
  max-height: 250px; /* Set a fixed height for the dropdown */
  overflow-y: auto; /* Enable vertical scrolling */
  background-color: white; /* Set background color */
  border: 1px solid #ccc; /* Add a border to the dropdown */
  border-radius: 4px; /* Round the corners */
  list-style-type: none; /* Remove default list styling */
  padding: 5px; /* Add padding */
  margin-top: 5px; /* Add some margin to the top */
  position: absolute; /* Absolute positioning */
  width: calc(100% - 10px); /* Full width of the input box minus padding */
  z-index: 10; /* Ensure it appears above other elements */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}

/* Styles for each client suggestion item */
.client-suggestions li {
  padding: 5px; /* Add padding to each item */
  cursor: pointer; /* Change cursor on hover */
}

/* Highlight item on hover */
.client-suggestions li:hover {
  background-color: #f0f0f0; /* Highlight color */
}


/* Responsive styles */
/* For phones */
@media (max-width: 480px) {
  body {
    font-size: 12px;
    overflow-x: hidden;
  }

  .reports-header h1 {
    font-size: 1.2em;
  }

  .logo {
    max-width: 70%;
  }

  .report-box,
  .form-section {
    width: 100%;
    padding: 5%;
    margin-bottom: 5%;
    border-radius: 10px;
  }

  .form-section label,
  .form-section input,
  .form-section select,
  .form-section textarea {
    font-size: 1em;
  }

  .form-section input,
  .form-section select,
  .form-section textarea {
    width: 100%;
    box-sizing: border-box;
  }

  .navigation-buttons {
    display: flex;
    flex-direction: column;
  }

  .navigation-buttons button {
    width: 100%;
    margin: 5px 0;
  }
  .save-progress-button, .release-button {
    position: fixed;
    bottom: 20px;
    width: 50px;
    height: 50px;
    padding: 0;
    border-radius: 50%;
    background-color: #1a2a6c;
    color: #fff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px; /* Smaller font size */
  }

  .save-progress-button {
    right: 20px; /* Position on the right side */
  }

  .release-button {
    right: 80px; /* Position it a bit left of the save button */
  }

  .save-progress-button:hover, .release-button:hover {
    background-color: #14224a;
  }

  /* Hide the text on mobile devices */
  .save-progress-text, .release-text {
    display: none;
  }

  .save-progress-button svg,
  .release-button svg {
    width: 20px;
    height: 20px;
  }

  .radio-group,
  .checkbox-group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .radio-group label,
  .checkbox-group label {
    margin-bottom: 10px;
  }
}
