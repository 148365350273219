.rank-dashboard {
  padding: 20px;
  text-align: center;
}

.rank-dashboard-header {
  text-align: center;
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
}


.rank-dashboard h1 {
  margin-bottom: 20px;
}

.rank-dashboard .chart-container {
  max-width: 800px;
  margin: auto;
}


.navigator-selection {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  /* Three equal columns */
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  border: 1px solid #ddd;
  max-height: 400px;
  /* Limit height to prevent excessive space */
  overflow-y: auto;
  /* Enable vertical scrolling if needed */

}

.navigator-selection label {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  /* Take full width of column */
}

.navigator-selection input[type="checkbox"] {
  margin-right: 5px;
}

.navigator-selection label:hover {
  background-color: #e6f7ff;
}

.chart-container {
  margin: 20px 0;
  padding: 15px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #fff;
}

.rank-dashboard-table {
  margin-top: 0px;
  width: 50%;
}

/* Responsiveness for Smaller Screens */
@media screen and (max-width: 1024px) {
  .navigator-selection {
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns on medium screens */
  }
}

@media screen and (max-width: 768px) {
  .navigator-selection {
    grid-template-columns: repeat(2, 1fr);
    /* 2 columns on tablets */
  }

  .rank-dashboard h1 {
    font-size: 1.5rem;
  }

  .rank-dashboard-table th,
  .rank-dashboard-table td {
    padding: 8px;
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .rank-dashboard-table {
    font-size: 0.75rem;
    /* Reduce font size for better fit */
  }

  .rank-dashboard-table th,
  .rank-dashboard-table td {
    padding: 5px;
    /* Reduce padding for compact view */
    white-space: nowrap;
    /* Prevent text wrapping */
  }

  .rank-dashboard-table-container {
    overflow-x: auto;
    /* Enable horizontal scrolling if necessary */
  }

  .navigator-selection {
    grid-template-columns: repeat(3, 1fr);
    /* 3 columns on medium screens */
    max-height: 200px;
  }
  
  .rank-dashboard .chart-container h2 {
    font-weight: 400px;
    margin: 0px;
  }
  


}