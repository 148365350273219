.previous_employees {
    padding: 20px;
    position: relative;
    /* Ensure proper stacking context */
  }
  
  .previous_employees-header {
    background-color: #23395d;
    color: white;
    padding: 8px 8px;
    z-index: 15;
    /* Keeps the header above other content */
    text-align: center;
    position: sticky;
    margin-bottom: 5px;
    top: 0;
  }
  
  .previous_employees-page {
    height: 100%;
    padding: 20px;
  }
  
  @media (max-width: 768px) {
    .previous_employees-table-container {
      max-height: 50vh;
      /* Reduce la altura en pantallas más pequeñas */
    }
  
    .previous_employees-table th,
    .previous_employees-table td {
      padding: 3px;
      font-size: 14px;
      /* Reduce el tamaño de fuente */
    }
  }
  
  .previous_employees-table-container {
    width: 100%;
    max-height: 67vh;
    /* El contenedor ocupará el 67% del alto de la pantalla */
    /* Adjust height to make space for header, footer, and other elements */
    overflow: auto;
    /* Allow both vertical and horizontal scrolling inside the table container */
    padding-top: 0px;
    box-sizing: border-box;
  }
  
  .previous_employees-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed; /* Establece un ancho fijo para las columnas */
  }
  
  .previous_employees-table th,
  .previous_employees-table td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: center;
    width: 150px;
  
  }
  
  .previous_employees-table th {
    position: sticky;
    top: 0;
    z-index: 1;
    text-align: center;
    table-layout: fixed;
  
  }
  .previous_employees-table th:nth-child(6),
  .previous_employees-table td:nth-child(6) {
    width: 320px; /* Ancho específico para la segunda columna */
  }
  .previous_employees-table th:nth-child(8),
  .previous_employees-table td:nth-child(8) {
    width: 250px; /* Ancho específico para la primera columna */
  }
  .previous_employees-table th:nth-child(10),
  .previous_employees-table td:nth-child(10) {
    width: 320px; /* Ancho específico para la primera columna */
  }
  
  .previous_employees-table th:nth-child(1),
  .previous_employees-table td:nth-child(2),
  .previous_employees-table th:nth-child(14)
   {
    width: 50px; /* Ancho específico para estas columnas */
  }
  
  .add-previous_employees-btn {
    background-color: #23395d;
    color: white;
    padding: 5px 10px;
    /* Ajusta el padding según sea necesario */
    margin-right: 10px;
    /* Espacio entre botones */
    margin-bottom: 8px;
    margin-top: 0;
    /* Elimina el margen superior si existe */
    border-radius: 5px;
    /* Bordes redondeados */
    cursor: pointer;
    width: 10%;
    /* Porcentaje del contenedor padre */
    display: block;
    /* Asegúrate de que esté en la misma línea si es necesario */
  }
  
  
  .buttons-container {
    display: flex;
    flex-direction: row;
    /* Alinea los botones horizontalmente */
    gap: 5px;
    /* Espacio entre los botones */
    width: 10%;
    /* Porcentaje del contenedor padre */
  }
  
  .delete-previous_employees-btn {
    background-color: #23395d;
    color: white;
    min-width: 120px;
    /* Ancho mínimo para mantener el tamaño constante */
    padding: 5px 5px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 35px;
    border-radius: 5px;
  }
  
  .delete-previous_employees-btn:hover {
    background-color: #3a5d8c;
  
  }
  
  .update-previous_employees-btn {
    background-color: #1a2a6c;
    color: white;
    margin-top: 0;
  
  }
  
  .add-previous_employees-btn:hover {
    background-color: #3a5d8c;
  }
  
  .previous_employees-search {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
    /* Asegura que el ancho del contenedor sea constante */
    max-width: 600px;
    /* O el tamaño que mejor se ajuste a tu diseño */
    flex-wrap: nowrap;
    /* Evita que los elementos se muevan a otra línea */
    margin-bottom: 20px;
    /* Espacio debajo del div */
  }
  
  .previous_employees-search input,
  .previous_employees-search select,
  .previous_employees-search button {
    padding: 10px;
    border-radius: 5px;
    height: 45px;
    /* Ensure all elements have the same height */
    width: max-content;
  }
  
  .previous_employees-search button {
    background-color: #23395d;
    color: white;
    min-width: 120px;
    /* Ancho mínimo para mantener el tamaño constante */
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    height: 45px;
    border-radius: 5px;
    /* Ajusta este valor para mayor redondez */
    transition: background-color 0.3s, transform 0.2s;
  }
  
  
  .previous_employees-search button:hover {
    background-color: #3a5d8c;
  
  }
  
  .employees_add_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0%;
    /* Ajusta el padding para que sea responsivo/difrente monitor*/
    box-sizing: border-box;
    /* Asegura que el padding se incluya dentro del tamaño total */
    z-index: 10000;
    /* Ensure overlay is on top of everything */
  }
  
  .overlay-content-previous_employees {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    width: 500px;
    /* Ajusta el ancho según tus necesidades */
    max-height: 90vh;
    /* Limita la altura al 80% del viewport */
    overflow-y: auto;
    /* Permite scroll interno si el contenido es demasiado alto */
    box-sizing: border-box;
    /* Asegura que el padding no incremente el tamaño total */
  }
  
  .overlay-content-previous_employees input {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .overlay-content-previous_employees select {
    width: 100%;
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .add_employees_close-btn {
  
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    margin-left: auto;
    /* Empuja el botón hacia la derecha */
  
  }
  
  .add_employees_close-btn:hover {
    background-color: rgb(164, 191, 216);
  
  }
  
  .overlay-add-previous_employees {
    padding: 20px 20px;
    border: none;
    background-color: #164678;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .overlay-delete-previous_employees {
    padding: 10px 20px;
    border: none;
    background-color: #ec2b2b;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .overlay-add-previous_employees:hover {
    background-color: #14224a;
  }
  
  .notas_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-size: 18px; /* Tamaño de letra aumentado */
  }
  
  .input-field {
    width: 800px; /* Ancho */
    margin: 0 auto; /* Centrado horizontal */
    display: block; /* Comportamiento de bloque */
    border-radius: 8px; /* Bordes redondeados */
    padding: 10px; /* Espaciado interno para mejorar la apariencia */
    
  }
  .printNotes {
    width: 700px; /* Ancho */
    margin: 0 auto; /* Centrado horizontal */
    display: block; /* Comportamiento de bloque */
    border: 2px solid#14224a;; /* Borde de 2px de color azul */
    font-size: 14px; /* Tamaño de letra aumentado */
  
  }
  
  .input-field:focus {
    border-color: #0056b3; /* Cambia el color del borde cuando está enfocado */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5); /* Sombra para resaltar el campo */
  }
  
  
  .Employees_checkboxes {
    display: flex;
    flex-direction: row;
    grid-template-columns: auto;
    gap: 5px;
  }
  
  
  .logo-container {
    display: flex;
    justify-content: center;
    /* Para centrar el logo */
    position: relative;
    /* Habilita el posicionamiento absoluto para el botón */
    margin-bottom: 0;
    /* Reduce o elimina el espacio debajo del logo */
    color: #F5F5F5;
  }
  
  .logo {
    max-width: 200%;
    height: 120px;
    padding: 0;
  }
  
  .close-button {
    position: absolute;
    top: 0;
    /* Alinea el botón con la parte superior */
    right: 0;
    /* Mueve el botón hacia la esquina derecha */
    padding: 5px 10px;
    background-color: #ff0000;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .log-table {
    margin-top: 0;
    /* Elimina cualquier margen superior que tenga la tabla */
    padding-top: 0;
    /* Elimina cualquier relleno superior si lo tiene */
    width: 100%;
    /* Ajusta según sea necesario */
    border-collapse: collapse;
    /* Si necesitas unir más las celdas */
  }
  
  .checkbox-returned {
    display: flex;
    font-size: 8px; /* Ajusta el tamaño del texto */
    margin: 0; /* Elimina márgenes alrededor del div */
    padding: 0; /* Elimina relleno dentro del div */
  
  }
  .checkbox-returned label {
    display: flex; /* Alinea el checkbox y el texto juntos */
    flex-direction: column; /* Pone el texto y el input en columna */
    font-size: 6px; /* Tamaño de fuente */
    color: #333; /* Color del texto */
    cursor: pointer; /* Cambia el cursor cuando pasas sobre los labels */
  }
  
  