.create-users-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    z-index: 9999;
}
.create-users-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    width: 100%;
    max-width: 400px; /* Max width for modal */
    text-align: center;
    position: relative; /* Make content container relative to position the close button inside it */
}

.create-users-content h2 {
    color: #333;
    margin-bottom: 20px;
    font-size: 24px;
}
.create-users-content form {
    display: flex;
    flex-direction: column;
}
.create-users-content label {
    font-size: 14px;
    color: #666;
    margin-bottom: 5px;
    text-align: left;
}
.create-users-content input[type="email"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 20px;
    transition: border-color 0.3s;
}
.create-users-content input[type="email"]:focus {
    outline: none;
    border-color: #23395d; /* Highlight on focus */
}
.create-users-content input[type="submit"] {
    background-color: #23395d;
    color: #fff;
    padding: 12px;
    font-size: 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}
.create-users-content input[type="submit"]:hover {
    background-color: #14224a;
    transition: background-color 0.3s;
}

.create-users-content p {
    font-size: 14px;
    color: #28a745;
    margin-top: 10px;
}

.create-users-content p.error {
    color: #dc3545;
}

.man-u-close {
    position: absolute;
    top: 10px;
    right: 20px;
    background: transparent;
    border: none;
    font-size: 18px;
    color: #333;
    cursor: pointer;
}

.man-u-close:hover {
    background-color: rgb(164, 191, 216) !important;
}