/* ---- Container & Header ---- */
.clients {
  padding: 20px;
  position: relative;
  /* Avoid forcing everything hidden. 
     Use overflow-x: hidden if you want to prevent horizontal scroll. */
  /* overflow: hidden; */
}

.clients-header {
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
  text-align: center;
}

/* ---- Buttons ---- */
.add-user-btn,
.download-excel-btn,
.navigate-opt-out-btn,
.find-duplicates-btn {
  background-color: #23395d;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 10px 10px 0;
}

.add-user-btn:hover,
.download-excel-btn:hover,
.navigate-opt-out-btn:hover,
.find-duplicates-btn:hover {
  background-color: #14224a;
}

/* ---- Search ---- */
.clients-search {
  display: flex;
  gap: 10px;
  margin: 10px 0;
}

.clients-search input,
.clients-search select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  height: 40px;
}

/* ---- Grid Container ---- */
.grid-header {
  position: sticky;
  top: 0;
  z-index: 10;
  overflow-x: auto; /* if needed to scroll horizontally */
}

/* Hide scrollbar only if you want a custom scroll or no scroll at all */
.grid-header::-webkit-scrollbar {
  display: none;
}


/* ---- Cells ---- */
.grid-cell {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

/* For row coloring, use classes. 
   You can apply them in your row rendering logic. */
.grid-cell.even-row {
  background-color: #f9f9f9;
}
.grid-cell.odd-row {
  background-color: #e0e0e0;
}
.grid-cell.selected-row {
  background-color: #3f4f69;
  color: white;
}

/* Hover styling; remove !important so we can conditionally override. */
.grid-cell:hover:not(.selected-row) {
  background-color: #616361;
  color: white;
}

/* ---- ID Cell Copy Icon ---- */
.id-cell {
  position: relative;
  padding: 0 5px; /* To ensure text and icon have some space */
}

/* Style the copy icon; hidden by default. */
.id-cell .copy-icon {
  position: absolute;
  right: 5px;
  font-size: 14px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  cursor: pointer;
}

/* On hover, make the icon visible. */
.id-cell:hover .copy-icon {
  opacity: 1;
}

/* ---- Overlay ---- */
.client_add_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;
}

.overlay-content-clients {
  background: white;
  padding: 20px;
  border-radius: 4px;
  width: 500px;
  max-width: 100%;
  overflow-y: auto;
  max-height: 90vh;
}

/* ---- Responsive Adjustments ---- */
@media screen and (max-width: 400px) {
  /* Smaller padding */
  .clients {
    padding: 10px;
  }

  /* Smaller header text */
  .clients-header {
    font-size: 16px;
    padding: 8px 10px;
  }

  /* Stack buttons vertically */
  .add-user-btn,
  .download-excel-btn,
  .navigate-opt-out-btn,
  .find-duplicates-btn {
    width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
    padding: 8px;
  }

  /* Search fields stack vertically */
  .clients-search {
    flex-direction: column;
  }
  .clients-search input,
  .clients-search select {
    width: 100%;
    font-size: 14px;
    height: 35px;
    padding: 0 5px;
  }

  /* Make grid container scroll horizontally if needed */
  .grid-header {
    overflow-x: auto;
  }
  .data-grid-container {
    overflow-x: auto;
    max-height: 50vh; /* or an appropriate size */
  }

  /* Slightly smaller cell minimums */
  .grid-cell {
    min-width: 120px;
    font-size: 13px;
    padding: 5px;
  }
  .id-cell {
    min-width: 130px;
    font-size: 12px;
  }
  .id-cell .copy-icon {
    font-size: 12px;
    right: 3px;
  }

  /* Overlay sizing on mobile */
  .client_add_overlay,
  .overlay-content-clients {
    width: 90%;
    max-height: 80vh;
    padding: 15px;
  }
}
