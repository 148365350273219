.scanner-table-container {
  width: 100%;
  max-height: 67vh;
  overflow: auto;
  padding-top: 0px;
  box-sizing: border-box;
}

.scanner-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}


.scanner-table th,
.scanner-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 150px;
  table-layout: auto;
  /* Permite que las columnas se ajusten al contenido */
  padding: 8px 10px;
  /* Reduce el padding dentro de las celdas */
  margin: 0;
  /* Asegura que no haya márgenes adicionales */
}

.scanner-table th {
  background-color: #23395d;
  font-weight: bold;
  position: sticky;
  width: 100px;
  /* Definir el ancho fijo */
  padding: 5px;
  /* Opcional: Espaciado dentro del encabezado */
  top: 0;
  z-index: 2;
}


.add-scanner-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior si existe */
  border-radius: 5px;
  /* Bordes redondeados */
  cursor: pointer;
  width: 10%;
  /* Porcentaje del contenedor padre */
  display: block;
  /* Asegúrate de que esté en la misma línea si es necesario */
}

.add-scanner-btn:hover {
  background-color: #3a5d8c;
}

.delete-scanner-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior */
  cursor: pointer;
  width: 100%;
  /* Ocupa todo el ancho del contenedor padre */
  border-radius: 5px;
  /* Bordes redondeados */
  display: block;
  /* Hace que el botón ocupe toda la línea */
}

.delete-scanner-btn:hover {
  background-color: #3a5d8c;
}

.scanner_add_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0%;
  /* Ajusta el padding para que sea responsivo/difrente monitor*/
  box-sizing: border-box;
  /* Asegura que el padding se incluya dentro del tamaño total */
  z-index: 10000;
  /* Ensure overlay is on top of everything */
}

.add_scanner_close-btn {

  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
  /* Empuja el botón hacia la derecha */

}

.add_scanner_close-btn:hover {
  background-color: rgb(164, 191, 216);

}

.overlay-add-scanner {
  background-color: #1a2a6c;
}

.overlay-add-scanner:hover {
  background-color: #14224a;
}

.overlay-delet-scanner {
  background-color: #1a2a6c;
}

.overlay-delet-scanner:hover {
  background-color: #d22121;
}

.error {
  color: rgb(199, 1, 1);
}

.scanner-page {
  height: 100%;
  padding: 20px;
}

.scanner-Description {
  max-width: 300px;
  overflow-x: auto;
  white-space: nowrap;}