.call-list-assign.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.call-list-assign .modal-content {
    background: white;
    padding: 20px;
    border-radius: 10px;
    width: 600px;
    max-height: 80%;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.call-list-assign .user-list {
    margin: 20px 0;
}

.call-list-assign .user-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #ccc;
    margin-bottom: 10px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.call-list-assign .user-item:hover {
    background-color: #f9f9f9;
    border-color: #999;
}

.call-list-assign .user-item.selected {
    border-color: #4caf50;
    background: #e8f5e9;
}

.call-list-assign .round-button {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #ddd;
    border: 1px solid #ccc;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
}

.call-list-assign .round-button.selected {
    background-color: #4caf50;
    border-color: #4caf50;
}

.call-list-assign .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.call-list-assign .assign-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.call-list-assign .assign-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.call-list-assign .close-btn {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.call-list-assign .close-btn:hover {
    background-color: #d32f2f;
}
