.Notifications-page {
    height: 100%;
    padding: 20px;
  }
  
  .Notifications-table-container {
    width: 100%;
    max-height: 67vh;
    /* El contenedor ocupará el 67% del alto de la pantalla */
    /* Adjust height to make space for header, footer, and other elements */
    overflow: auto;
    /* Allow both vertical and horizontal scrolling inside the table container */
    padding-top: 0px;
    box-sizing: border-box;
  }
  
  .Notifications-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: auto;
  }
  
  .Notifications-table th,
  .Notifications-table td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: center;
    width: 150px;
    table-layout: auto;
    justify-content: center;/* Centrar horizontalmente */
    align-items: center;/* Centrar verticalmente */
    padding: 8px 10px;
    /* Reduce el padding dentro de las celdas */
    margin: 0;
    /* Asegura que no haya márgenes adicionales */
  }
  
  .Notifications-table th {
    background-color: #23395d;
    font-weight: bold;
    position: sticky;
    width: 100px;
    /* Definir el ancho fijo */
    padding: 5px;
    /* Opcional: Espaciado dentro del encabezado */
    top: 0;
    z-index: 2;
  }
  
  .add-Notifications-btn {
    background-color: #23395d;
    color: white;
    padding: 5px 10px;
    /* Ajusta el padding según sea necesario */
    margin-right: 10px;
    /* Espacio entre botones */
    margin-bottom: 8px;
    margin-top: 0;
    /* Elimina el margen superior si existe */
    border-radius: 5px;
    /* Bordes redondeados */
    cursor: pointer;
    width: 12%;
    /* Porcentaje del contenedor padre */
    display: block;
    /* Asegúrate de que esté en la misma línea si es necesario */
  
  }
  
  .add-Notifications-btn:hover {
    background-color: #3a5d8c;
  }
  
  .Notifications_add_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    z-index: 10000;
  }
  
  .add_Notifications_close-btn {
  
    background-color: transparent;
    border: none;
    font-size: 18px;
    cursor: pointer;
    padding: 5px;
    margin-left: auto;
    /* Empuja el botón hacia la derecha */
  
  }
  
  .add_Notifications_close-btn:hover {
    background-color: rgb(164, 191, 216);
  
  }
  
  .overlay-add-Notifications {
    background-color: #1a2a6c;
  }
  
  .overlay-add-Notifications:hover {
    background-color: #14224a;
  }
  
  .overlay-delet-Notifications {
    background-color: #1a2a6c;
  }
  
  .overlay-delet-Notifications:hover {
    background-color: #d22121;
  }
  
  .warning {
    color: #d22121;
  }
  

  .overlay-content-notification {
    background-color: white;
    padding: 20px;
    border-radius: 4px;
    width: 500px;
    max-width: 100%;
    max-height: 95%;
    margin-top: 3%;
    overflow-y: auto;
    overflow-x: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .header-row-notification {
    display: flex;
    justify-content: flex-end;
    /* Alinea todos los elementos hacia la derecha */
    align-items: center;
    /* Alinea los elementos verticalmente */
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: #fdfeff;
    color: rgb(14, 13, 13);
    padding: 10px;
    border-bottom: 0;
  }
  .header-title-notification {
    flex-grow: 1;
    /* Hace que el título ocupe todo el espacio disponible */
    text-align: center;
    /* Centra el texto */
    margin: 0;
    /* Elimina el margen predeterminado de <h2> */
  }
