.touchpoint-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .touchpoint-modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 80%;
    max-height: 80%;
    overflow-y: auto;
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
  }
  
  .client-details {
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line when max width is reached */
    gap: 20px; /* Add space between items */
  }

  .client-details h3{
    width: 100%;
    margin: 0%;
  }
  
  .client-details p {
    margin: 0; /* Remove default margins for inline consistency */
    white-space: nowrap; /* Prevent text from wrapping within each item */
  }
  
  .interactions-list {
    border-top: 1px solid #ddd;
    padding-top: 10px;
  }
  .interaction-item {
    display: flex; /* Use flexbox for inline layout */
    flex-wrap: wrap; /* Allow wrapping to the next line when the max width is reached */
    gap: 20px; /* Add space between items */
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #eee;
    border-radius: 4px;
    background-color: #f9f9f9; /* Optional: Add a subtle background color */
    position: relative; /* Make this a positioned ancestor */
  }
  
  .interaction-item p {
    margin: 0; /* Remove default margin for consistent spacing */
    flex: 1 1 calc(33.33% - 20px); /* Allow items to grow, shrink, and take up 33% of the row */
    white-space: nowrap; /* Prevent text wrapping within each item */
  }
  
  .delete-interaction-button {
    position: absolute;
    top: 10px; /* Adjust distance from the top */
    right: 10px; /* Adjust distance from the right */
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 0.9em;
  }
  
  .delete-interaction-button:hover {
    background-color: #d43d3d;
  }

  .add-interaction-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Content Box for the Add Interaction Modal */
  .add-interaction-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    height: 90%;
    overflow-y: auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    animation: fadeIn 0.3s ease-in-out;
    position: relative;
    z-index: 1001;
  }
  
  /* Fade-in Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.9);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  /* Header Styles for Modal */
  .add-interaction-modal-content h3 {
    font-size: 1.5em;
    margin-bottom: 1em;
    color: #333;
    text-align: center;
  }
  
  /* Label and Input Styling */
  .add-interaction-modal-content label {
    display: block;
    margin-bottom: 10px;
    color: #555;
    font-size: 0.9em;
  }
  
  .add-interaction-modal-content input[type="text"],
  .add-interaction-modal-content input[type="date"],
  .add-interaction-modal-content input[type="time"],
  .add-interaction-modal-content textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 0.95em;
    box-sizing: border-box;
  }
  
  .add-interaction-modal-content input[type="checkbox"] {
    margin-right: 8px;
  }

  .add-interaction-modal-content input:disabled, textarea:disabled {
    background-color: #f5f5f5;
    cursor: not-allowed;
  }
  
  
  /* Button Styling */
  .add-interaction-modal-content button {
    display: inline-block;
    padding: 10px 15px;
    margin-top: 10px;
    margin-right: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .add-interaction-modal-content button:hover {
    background-color: #0056b3;
  }
  
  .add-interaction-modal-content button:last-child {
    background-color: #6c757d;
  }
  
  .add-interaction-modal-content button:last-child:hover {
    background-color: #5a6268;
  }
  
  /* Error Message Styling */
  .add-interaction-modal-content .error-message {
    color: #ff4d4d;
    font-size: 0.85em;
    margin-top: 10px;
    text-align: center;
  }
  