.issue_Ticket_List-page {
  height: 100%;
  padding: 20px;
}

.issue_Ticket_List-table-container {
  width: 100%;
  max-height: 67vh;
  /* El contenedor ocupará el 67% del alto de la pantalla */
  /* Adjust height to make space for header, footer, and other elements */
  overflow: auto;
  /* Allow both vertical and horizontal scrolling inside the table container */
  padding-top: 0px;
  box-sizing: border-box;
}

.issue_Ticket_List-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.issue_Ticket_List-table th,
.issue_Ticket_List-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 150px;
  table-layout: auto;
  justify-content: center;
  /* Centrar horizontalmente */
  align-items: center;
  /* Centrar verticalmente */
  padding: 8px 10px;
  /* Reduce el padding dentro de las celdas */
  margin: 0;
  /* Asegura que no haya márgenes adicionales */
}

.issue_Ticket_List-table th {
  background-color: #23395d;
  font-weight: bold;
  position: sticky;
  width: 100px;
  /* Definir el ancho fijo */
  padding: 5px;
  /* Opcional: Espaciado dentro del encabezado */
  top: 0;
  z-index: 2;
}

.add-issue_Ticket_List-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px;
  /* Ajusta el padding según sea necesario */
  margin-right: 10px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0;
  /* Elimina el margen superior si existe */
  border-radius: 5px;
  /* Bordes redondeados */
  cursor: pointer;
  width: 12%;
  /* Porcentaje del contenedor padre */
  display: block;
  /* Asegúrate de que esté en la misma línea si es necesario */

}

.add-issue_Ticket_List-btn:hover {
  background-color: #3a5d8c;
}

.issue_Ticket_List_add_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  /* Reduce el margen superior para acercarlo al botón */
  z-index: 10000;
  /* Ensure overlay is on top of everything */
}

.add_issue_Ticket_List_close-btn {

  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
  /* Empuja el botón hacia la derecha */

}

.add_issue_Ticket_List_close-btn:hover {
  background-color: rgb(164, 191, 216);

}

.overlay-add-issue_Ticket_List {
  background-color: #1a2a6c;
}

.overlay-add-issue_Ticket_List:hover {
  background-color: #14224a;
}

.overlay-delet-issue_Ticket_List {
  background-color: #1a2a6c;
}

.overlay-delet-issue_Ticket_List:hover {
  background-color: #d22121;
}

.warning {
  color: #d22121;
}

.purchase_link {
  max-width: 150px;
  overflow-x: auto;
  white-space: nowrap;
  overflow-x: auto;
  padding: 10px;
  box-sizing: border-box;

}

.checkbox_notify {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;

}

.checkbox_container {
  display: flex;
  align-items: center;
  min-width: 200px;

}


.checkbox_notify label {
  overflow: visible;
  max-width: none;
  text-overflow: clip;
  color: rgb(5, 27, 58);
  font-weight: bold;
}



.print-ticket-btn {
  background-color: #23395d;
  color: white;
  min-width: 80px;
  padding: 5px 5px;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 5px;
  height: 35px;
  border-radius: 5px;
}

.overlay-print {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0%;
  /* Ajusta el padding para que sea responsivo/difrente monitor*/
  box-sizing: border-box;
  /* Asegura que el padding se incluya dentro del tamaño total */
  z-index: 10000;
  /* Ensure overlay is on top of everything */
}

.overlay_tickets {
  background-color: #f9f9f9;
  color: rgb(16, 15, 15);
  padding: 20px;
  border-radius: 20px;
  width: 80%;
  max-width: 600px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-height: 100vh;
  /* Limita la altura máxima al 90% de la ventana */
  display: flex;
  overflow-y: auto;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.3);
  /* Sombra sutil */
  flex-direction: column;
  align-items: center;
}

.logo {
  max-width: 150px;
  margin-bottom: 10px;
}

.ticket-details {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.ticket-details td,
.ticket-details th {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: left;
}

.ticket-details th {
  background-color: #f2f2f2;
}

.ticket-details img {
  margin-bottom: 10px;
  max-width: 150px;
}

.overlay-buttons-ticket {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.overlay-buttons-ticket button {
  background-color: #23395d;
  color: white;
  border: none;
  padding: 12px 24px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.overlay-buttons-ticket button:hover {
  background-color: #2b445e;
}

.overlay-buttons-ticket button:last-child {
  background-color: #6c757d;
}

.overlay-buttons-ticket button:last-child:hover {
  background-color: #5a6268;
}

@media print {

  /* Hide everything else */
  /* Ocultar todo el contenido que no está en .overlay_tickets */
  body>* {
    visibility: hidden;
  }

  /* Ensure that only the .overlay_tickets is visible */
  .overlay_tickets,
  .overlay_tickets * {
    visibility: visible;
  }

  .overlay_tickets {
    position: static;
    /* Reset position for printing */
    top: 0;
    /* Ensure it's aligned to the top */
    left: 0;
    text-align: left;
    box-shadow: none;
    transform: none;
    /* Remove any transformation */
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 9999;
    /* Keep it on top */
    background: white;
    /* Ensure it has a background for printing */
    overflow: visible;
    page-break-before: avoid;
    /* Avoid starting on a new page */
    page-break-inside: avoid;
    /* Avoid breaking inside the overlay */
    page-break-after: auto;
    /* Ensure no unnecessary page breaks after */
  }

  .overlay-buttons-ticket {
    display: none;
  }

  .ticket-details {
    page-break-inside: avoid;
  }

  @page {
    margin: 10mm;
    /* Adjust page margins if needed */
    size: auto;
  }

  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 14px;
    height: auto;
    align-items: center;

  }
}

.problem,
.problem2,
.problem3 {
  max-width: 200px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  box-sizing: border-box;
}

.steps_taken,
.steps_taken2,
.steps_taken3 {
  max-width: 200px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  box-sizing: border-box;
}

.resolution,
.resolution2,
.resolution3 {
  max-width: 200px;
  overflow-x: auto;
  white-space: nowrap;
  padding: 10px;
  box-sizing: border-box;
}

.input-long-text {
  width: 100%;
  /* Ensure it takes up the full width of its container */
  max-width: 700px;
  /* Set the maximum width */
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  min-height: 100px;
  /* Initial height */
}

.overlay-content-ticket {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
  width: 600px;
  max-width: 100%;
  max-height: 95%;
  margin-top: 3%;
  overflow-y: auto;
  overflow-x: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

}

/* Se usa en todas las page */


.overlay-content-ticket input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Se usa en todas las page */

.overlay-content-ticket select {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.cancel-reason-container {
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  background: #f9f9f9;
}

.confirm-cancel-button {
  background-color: darkred;
  color: white;
  padding: 8px;
  border: none;
  cursor: pointer;
}