inventory {
    padding: 20px;
    position: relative; /* Ensure proper stacking context */
  }
  
  .inventory-header {
    background-color: #23395d;
    color: white;
    padding: 10px 20px;
    z-index: 10; /* Keeps the header above other content */
    text-align: center;
    position: sticky;
    margin-bottom: 10px;
    top: 0;

  }
  .total
  {
    text-align: center;
  }

  .datePicker-container {
    display: flex; /* Flexbox para alinear en fila */
    gap: 15px; /* Espacio entre los divs */
    align-items: center; /* Alinea verticalmente en el centro */

 
  }

  .dp {
    display: flex;
    flex-direction: column; /* Alinea la etiqueta y el input en columna */
  
  }
  
  .datePicker label {
    margin-right: 10px; /* Espacio entre el texto del label y el DatePicker */
  }


  .date-error {
    color: red;
    margin-top: 10px;
  }
  
  body {
    font-family: Arial, sans-serif;
  }
  
  .placeholder-option {
    color: lightgray;
  }
  
  .inventory_Table {
    margin-top: 10px;
  }
  
  table {
    margin-top: 10px;
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
  }
  
.mouse-table th,
.mouse-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 150px;
  table-layout: auto; /* Permite que las columnas se ajusten al contenido */
  padding: 8px 10px; /* Reduce el padding dentro de las celdas */
  margin: 0; /* Asegura que no haya márgenes adicionales */
}
  
  th, td {
    border: 1px solid #ccc;
    padding: 5px;
    text-align: left;
  }
  
  tbody tr:nth-child(odd) {
    background-color: #F5F5F5; /* Light grey */
  }
  
  tbody tr:nth-child(even) {
    background-color: #E0E0E0; /* Slightly darker grey */
  }
  
  tbody tr:hover {
    background-color: #D5E8D4; /* Light greenish highlight */
  }
  
  th {
    background-color: #23395d;
    color: white;
    position: relative;
    cursor: pointer;
  }
  
  th:hover {
    background-color: #3a5d8c; /* Lighter blue */
  }
  
  th .sort-arrow {
    margin-left: 5px;
    font-size: 0.8em;
  }

  .edit-btn {
    background-color: #1a2a6c;
    color: white;
  }
  
  .sort-ascending::after {
    content: '▲';
    position: absolute;
    right: 10px;
  }
  .sort-descending::after {
    content: '▼';
    position: absolute;
    right: 10px;
  }

  .rangeDate{
    font-weight: bold;
    font-size:25 px;
    color:red


  }

  .totalMoney{

    text-align: right; /* Alinea el texto a la izquierda */
    font-weight: bold;
    font-size: 15px;
    color: red;
    margin-left: 45%; /* Espacio entre el input y el label */
    text-align: left;
    vertical-align: middle; /* Alinea el label verticalmente al centro del input */

  }

  .input_search {
    float: right;
  }
  
  .grand-total-label {
    position: sticky; /* Habilita el comportamiento sticky */
    top: 0; /* La posición sticky se activará cuando el elemento llegue al borde superior */
    margin-top: 10px; /* Agrega un espacio superior si es necesario */
    margin-left: auto; /* Asegura que el div esté alineado a la derecha */
    margin-right: 20px; /* Asegura separación del borde derecho */
    font-weight: bold; /* Resalta el texto */
    color: red; /* Mantén el color rojo del texto */
    font-size: 16px; /* Ajusta el tamaño del texto */
    text-align: right; /* Alinea el texto hacia la derecha */
    padding: 10px; /* Espaciado interno para un diseño más limpio */
    z-index: 1000; /* Asegura que esté por encima de otros elementos */
    background: white; /* Asegura que el texto no quede encima de otros elementos visibles */
  }
  

  .dp .react-datepicker__close-icon {
    cursor: pointer;
    background-color: transparent;
    border: 0;
    outline: 0;
    padding: 0 12px 0 0 ;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: table-cell;
    vertical-align: middle;
}
  .dp .react-datepicker__close-icon::after {
    cursor: pointer;
    background-color:red;
    color: #fff;
    border-radius: 50%;
    height: 18px;
    padding: 4px;
    font-size: 14px;
    line-height: 1;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    content: "×";
}
.home-button {
  display: flex;
  flex-direction: column; /* Coloca el ícono encima del texto (si aplica) */
  align-items: center; /* Centra horizontalmente */
  justify-content: center; /* Centra verticalmente */
  background: transparent; /* Fondo transparente */
  border: none; /* Sin bordes */
  padding: 0; /* Sin padding extra */
  cursor: pointer; /* Cursor en forma de mano */
  margin-left:55px;
}

.home-button:hover {
  color: #0F2747; /* Cambia el color al pasar el mouse */
}

.no-items-message {
  color: #d9534f;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  padding: 12px;
  background-color: #f9d6d5;
  border: 1px solid #d9534f;
  border-radius: 8px;
  width: 80%;
  margin: 20px auto;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
}