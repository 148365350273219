.inventory-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0%;
  /* Ajusta el padding para que sea responsivo/difrente monitor*/
  box-sizing: border-box;
  /* Asegura que el padding se incluya dentro del tamaño total */
  z-index: 10000;
  /* Ensure overlay is on top of everything */
}


.custom-overlay {
  background-color: #f9f9f9;
  color: rgb(16, 15, 15);
  padding: 20px;
  border-radius: 15px;
  width: 100%;
  max-width: 1000px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-height: 100vh;
  /* Limita la altura máxima al 90% de la ventana */
  overflow-y: auto;
  /* Permite el desplazamiento vertical si excede la altura */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.custom-overlay .content {
  background-color: #f9f9f9;
  /* Fondo gris claro */
  padding: 10px;
  color: rgb(16, 15, 15);
  /* Texto negro */
  border-radius: 4px;
  width: 100%;
  backdrop-filter: blur(5px);
  /* Aplica desenfoque al contenido de fondo */
  max-width: 100%;
  margin-top: 30px;
  /* Ajuste moderado para un poco de espacio */
  max-height: 100vh;
  /* Limita la altura máxima al 80% de la ventana */
  margin-top: 50%;
  /* reducirel top de la pantalla add */
  overflow-y: auto;
  /* Scroll si el contenido excede el espacio */
  table-layout: auto;
  /* Ajusta el ancho de las columnas según el contenido */
}

.custom-overlay .header-row {
  display: flex;
  justify-content: space-between;
  /* Espacio entre el título y el botón */
  align-items: center;
  /* Alinea verticalmente */
}

.custom-overlay .header-title {
  flex-grow: 1;
  /* Hace que el título ocupe todo el espacio disponible */
  text-align: center;
  /* Centra el texto */
  margin: 0;
  /* Elimina el margen predeterminado de <h2> */
}

.justified-text p {
  text-align: justify;
}

.custom-overlay .close-button {
  background:   #23395d;
  border-radius: 5px;
  font-size: 1rem;
  color: rgb(253, 251, 251);
  cursor: pointer;

}

.custom-overlay table {
  width: 100%;
  margin: 0 auto;
  border-collapse: collapse;
}

.custom-overlay table th,
.custom-overlay table td {
  border: 1px solid #ddd; /* Borde alrededor de las celdas */
  padding: 2px; /* Espaciado interno en las celdas */
  text-align: center; /* Alineación del texto */
  word-wrap: break-word; /* Permite el ajuste de contenido largo */
  white-space: normal; /* Asegura que el texto pueda romperse en varias líneas */
  font-size: 14px; /* Ajusta el tamaño de la fuente */
}

.custom-overlay table th {
  font-weight: bold; /* Resalta el texto de los encabezados */
  text-align: center; /* Alineación central para los encabezados */
  line-height: 1.2; /* Ajusta el espacio entre líneas */
}


.custom-overlay table td:nth-child(1) {
  width: 90px; /* Ancho fijo de la segunda columna */
}
.custom-overlay table td:nth-child(2) {
  width: 60px; /* Ancho fijo de la segunda columna */
}
.custom-overlay table td:nth-child(8) {
  width: 100px; /* Ancho fijo de la segunda columna */
}

.print-btn {
  background-color: #23395d;
  align-items: flex-end;
  /* Alinea los botones a la derecha */
  color: white;
  padding: 10px 10px;
  margin-right: 8px;
  /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 8px;
  /* Espacio entre el header y el botón */
  cursor: pointer;
  border: none;
  width: 10%;
  /* Porcentaje del contenedor padre */

}

.additional-notes {
  margin-top: 20px;
  /* Espacio arriba de la sección de notas */
}

.custom-overlay div {

  /* Fondo gris claro */
    padding: 1px;
  /* Espaciado interno */
  /* border-radius: 8px;
  /* Bordes redondeados */
   margin-bottom: 4px;
  /* Espacio debajo del div */
}

.custom-overlay div p {
  margin: 5px 0;
  /* Espacio arriba y abajo de los párrafos */
  line-height: 1.6;
  /* Espaciado entre líneas para mayor legibilidad */
  color: #333;
  /* Color de texto gris oscuro para mejor contraste */
}

/* Ensure the table layout allows columns to adjust based on content */
.table-auto-width, .table-auto-width th, .table-auto-width td {
  table-layout: auto;
}

/* Specific styling for tag# and date columns */
.tag-column, .date-column {
  white-space: nowrap; /* Prevent content wrapping */
  width: auto; /* Let the content decide the width */
  text-align: center; /* Center-align text */
}

@media print {

  /* Hide everything else */
  /* Ocultar todo el contenido que no está en .custom-overlay */
  body>* {
    visibility: hidden;
  }

  /* Ensure that only the .custom-overlay is visible */
  .custom-overlay,
  .custom-overlay * {
    visibility: visible;
  }


  /* Mostrar los spans que contienen los valores durante la impresión */
  span[id^="print"] {
    display: inline;
    font-size: inherit;
  
  }

  input[type="checkbox"] {
    display: inline-block; /* Asegura que los checkboxes sean visibles */
    transform: scale(1.2); /* Opcional: Ajusta el tamaño si es necesario */
    margin: 5px; /* Ajusta el espaciado si es necesario */
  }

  .custom-overlay {
    position: static;
    /* Reset position for printing */
    top: 0;
    /* Ensure it's aligned to the top */
    left: 0;
    transform: none;
    /* Remove any transformation */
    width: 100%;
    margin: 0 !important;
    padding: 0 !important;
    z-index: 9999;
    /* Keep it on top */
    background: white;
    /* Ensure it has a background for printing */
    overflow: visible;
    page-break-before: avoid;
    /* Avoid starting on a new page */
    page-break-inside: avoid;
    /* Avoid breaking inside the overlay */
    page-break-after: auto;
    /* Ensure no unnecessary page breaks after */
  }


  .custom-overlay .close-button {
    display: none;
    /* Hide close button during printing */
  }

  .print-btn {
    display: none;
  }

  @page {
    margin: 8mm;
    /* Adjust page margins if needed */
    size: auto;
  }

  html,
  body {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 12px;
    height: auto;
    /* Ensure height auto-adjusts for content */
  }
}