/* Main container */
.counties-header {
    background-color: #23395d;
    color: white;
    padding: 10px 20px;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .counties {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
  }
  
  /* County card container */
  .county-section {
    background-color: #f7f7f7;  /* Light gray background */
    border-radius: 12px;         /* Rounded corners */
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);  /* Subtle shadow */
    margin-bottom: 20px;         /* Space between county cards */
    padding: 20px;
  }
  
  /* County title styling */
  .county-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #ddd;  /* Light bottom border */
  }
  
  .county-title h2 {
    font-size: 1.5em;
    margin: 0;
    color: #333;
  }
  
  .DeleteCountyBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 1.2em;
    color: #c00;
  }
  
  .DeleteCountyBtn:hover {
    color: #900;  /* Darker red on hover */
  }
  
  /* City section styling */
  .city-section {
    margin-top: 20px;
  }
  
  .city-section h3 {
    font-size: 1.3em;
    margin-bottom: 10px;
    color: #555;
  }
  
  /* Location card styling */
  .location-card {
    background-color: white;  /* White background for location card */
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-bottom: 15px;
  }
  
  .location-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .location-header input {
    font-size: 1em;
    padding: 5px;
    margin: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 250px;
  }
  
  /* Success message styling */
  .success-message {
    color: green;
    font-size: 0.9em;
    margin-top: 10px;
  }
  
  /* Navigator list */
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  li {
    padding: 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Add county section */
  .add-county-section {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .add-county-section input, .add-county-section select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    font-size: 1em;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .add-county-section-toggle {
    background-color: #f7f7f7;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    padding: 15px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .toggle-arrow {
    font-size: 1.5em;
    transition: transform 0.3s ease;
  }
  
  .toggle-arrow.open {
    transform: rotate(180deg);
  }
  
  .add-county-section-content {
    display: none;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    margin-top: 10px;
  }
  
  .add-county-section-content.show {
    display: block;
  }
  
  /* Button Styles */
  .AddCountyBtn,
  .SaveBtn,
  .AddNavigatorBtn,
  .AddLocationBtn,
  .AddCityBtn {
    background-color: #1a2a6c;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    padding: 5px;
  }
  
  .AddCountyBtn:hover,
  .SaveBtn:hover,
  .AddNavigatorBtn:hover,
  .AddLocationBtn:hover,
  .AddCityBtn:hover {
    background-color: #14224a;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .AddCountyBtn {
    padding: 3px 12px;
  }
  
  /* Save, Delete, and Add Navigator Buttons */
  .SaveBtn,
  .AddNavigatorBtn {
    margin-left: 5px;
  }
  
  .DeleteBtn {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 10%;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
  }
  
  .DeleteBtn svg {
    width: 100%;
    height: 100%;
    color: #c00;
  }
  
  .DeleteBtn:hover svg {
    color: #900;
  }
  
  .DeleteBtn:hover {
    background-color: transparent !important;
  }
  
  .EditBtn {
    background-color: transparent;
    color: #000000;
    border: none;
  }
  
  .EditBtn:hover {
    color: #7e7e7e;
  }
  
  .action-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .counties input[type="text"] {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 5px;
    margin-right: 10px;
  }
  
  .county-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .add-city-section, .add-location-section {
    margin: 10px 0;
  }
  
  .add-city-section input, .add-location-section input {
    margin-right: 10px;
  }
  
  /* Disabled button styles */
  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed !important;
    color: #666666;
  }
  
  button:disabled:hover {
    background-color: #cccccc;
    color: #666666;
  }
  