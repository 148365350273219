.phone-page {
  height: 100%;
  padding: 20px;
}

.phone-table-container {
  width: 100%;
  max-height: 67vh;
  /* El contenedor ocupará el 67% del alto de la pantalla */
  /* Adjust height to make space for header, footer, and other elements */
  overflow: auto;
  /* Allow both vertical and horizontal scrolling inside the table container */
  padding-top: 0px;
  box-sizing: border-box;
}

.phone-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: auto;
}

.phone-table th,
.phone-table td {
  border: 1px solid #ccc;
  padding: 5px;
  text-align: center;
  width: 150px;
  table-layout: auto;
  /* Permite que las columnas se ajusten al contenido */
  padding: 8px 10px;
  /* Reduce el padding dentro de las celdas */
  margin: 0;
  /* Asegura que no haya márgenes adicionales */
}

.phone-table th {
  background-color: #23395d;
  font-weight: bold;
  position: sticky;
  width: 100px;
  /* Definir el ancho fijo */
  padding: 5px;
  /* Opcional: Espaciado dentro del encabezado */
  top: 0;
  z-index: 2;
}

.add-phone-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px; /* Ajusta el padding según sea necesario */
  margin-right: 10px; /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0; /* Elimina el margen superior si existe */
  border-radius: 5px; /* Bordes redondeados */
  cursor: pointer;
  width: 10%; /* Porcentaje del contenedor padre */
  display: block; /* Asegúrate de que esté en la misma línea si es necesario */
}
.add-phone-btn:hover {
  background-color: #3a5d8c;
}

.delete-phone-btn {
  background-color: #23395d;
  color: white;
  padding: 5px 10px; /* Ajusta el padding según sea necesario */
  margin-right: 10px; /* Espacio entre botones */
  margin-bottom: 8px;
  margin-top: 0; /* Elimina el margen superior */
  cursor: pointer;
  width: 100%; /* Ocupa todo el ancho del contenedor padre */
  border-radius: 5px; /* Bordes redondeados */
  display: block; /* Hace que el botón ocupe toda la línea */
}
.delete-phone-btn:hover {
  background-color:  #3a5d8c;
}



.phone_add_overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5px;
  /* Reduce el margen superior para acercarlo al botón */
  z-index: 10000;
  /* Ensure overlay is on top of everything */
}


.add_phone_close-btn {

  background-color: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
  /* Empuja el botón hacia la derecha */

}

.add_phone_close-btn:hover {
  background-color: rgb(164, 191, 216);

}

.overlay-add-phone {
  background-color: #23395d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 5px; /* Reducido el padding vertical para disminuir la altura */
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto; /* Ajusta el ancho al contenido del texto */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
}

.overlay-add-phone:hover {
  background-color: #0d2465;
}

.overlay-delet-phone {
  background-color: #23395d;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 5px; /* Reducido el padding vertical para disminuir la altura */
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: auto; /* Ajusta el ancho al contenido del texto */
  white-space: nowrap; /* Evita que el texto se divida en varias líneas */
}

.overlay-delet-phone:hover {
  background-color: #de151f;
}


.phone_btn_showpassword {
  position: relative;
  display: inline-block;
  width: 100%;
  /* Ajusta este valor según sea necesario */
}

.phone-input_password {
  width: 100%;
  padding-right: 60px;
  /* Espacio suficiente para el botón */
  box-sizing: border-box;
  /* Asegura que el padding se incluya en el ancho total del input */
}

.phone_password {
  position: absolute;
  right: 10px;
  top: 65%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 15px;
  /* Ajusta el tamaño de la fuente según sea necesario */
  color: #181717;
  /* Cambia este color según tu diseño */
}

.searchBar {
  display: flex;
  align-items: center;
  gap: 10px;
}

.phone-EID
{
  max-width: 150px;
  /* Ajusta el ancho máximo para esta columna */
  overflow-x: auto;
  /* Permite el scroll horizontal si el contenido es demasiado largo */
  white-space: nowrap;
  /* Evita que el texto se rompa en múltiples líneas */
}