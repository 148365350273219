/* Main container */
.mobile-outreach-header {
  background-color: #23395d;
  /* Dark blue */
  color: white;
  padding: 10px 20px;
  text-align: center;
  margin-bottom: 20px;
  width: 100%;
}

.mobile-outreach {
  padding: 20px;
  max-width: 100%;
  margin: 0 auto;
  background-color: #f7f7f7;
  /* Light gray background */
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  /* Subtle shadow */
}

.mobile-outreach-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile-outreach-content {
  max-width: 600px;
  width: 100%;
}

/* Info card styling */
.info-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  width: 100%;
}

.info-card h2 {
  margin-top: 0;
  color: #1a2a6c;
  /* Dark blue */
}

.info-card input {
  width: 100%;
  padding: 5px;
  font-size: 1em;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.info-card input[type="date"] {
  -webkit-appearance: none;
  /* Removes default styles for consistency */
  appearance: none;
  /* Removes default styles for consistency */
  width: 100%;
  /* Ensures it matches other input widths */
  padding: 5px;
}

.info-card p {
  margin-bottom: 10px;
}

/* New style for centering the submit button */
.button-container {
  display: flex;
  justify-content: center;
  /* Centers the button horizontally */
  margin-top: 20px;
  /* Adds some space from the form inputs */
}

.SubmitBtn {
  padding: 10px 20px;
  background-color: #1a2a6c;
  /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.SubmitBtn:hover {
  background-color: #14224a;
  /* Darker blue on hover */
}


/* Disabled Button Styling */
.SubmitBtn:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  color: #666666;
}

.SubmitBtn:disabled:hover {
  background-color: #cccccc;
  color: #666666;
}

.exportToExcelBtn {
  position: absolute;
  top: 20%;
  /* Distance from the top of the container */
  right: 5%;
  /* Distance from the right of the container */
  padding: 10px 20px;
  background-color: #1a2a6c;
  /* Button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Operation Hours Styling */
.operation-hours-card {
  margin-top: 20px;
}

.operation-hours-row {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  /* Add more space between each row */
  gap: 30px;
  /* Space between each column */
}

.operation-hours-day {
  display: flex;
  align-items: center;
  min-width: 150px;
  /* Adjust this width as needed */
}

.operation-hours-day input[type="checkbox"] {
  margin-left: 60px;
  margin-right: 0px;
  /* Space between checkbox and day name */
  vertical-align: middle;
  /* Ensures checkbox aligns with text */
  width: min-content;
}

.operation-hours-day {
  flex: 1;
  /* Allow the day label to take as much space as needed */
}

.operation-hours-row input[type="time"] {
  width: 120px;
  /* Ensure consistent width for all time inputs */
  flex-shrink: 0;
  /* Prevent shrinking */
}

button.apply-same-time {
  margin-bottom: 15px;
  /* Space between the button and the rows */
}


.apply-same-time-btn:hover {
  background-color: #14224a;
}


/* Mobile responsiveness */
@media (max-width: 600px) {
  .operation-hours-row {
    flex-direction: row;
    /* Ensure items are horizontally aligned on smaller screens */
    gap: 5px;
    /* Reduce gap between elements on mobile */
  }

  .operation-hours-day,
  .operation-hours-row input[type="time"] {
    width: 100px;
    /* Smaller width for the time inputs */
  }

  .apply-same-time-btn {
    width: 100%;
    /* Full width for the button on mobile */
  }

  /* Adjust the checkboxes and labels on smaller screens */
  .operation-hours-day {
    min-width: 100px;
    /* Smaller width for day names */
    flex: 1;
  }

  .operation-hours-day input[type="checkbox"] {
    margin-left: 20px;
    margin-right: 2px;
    /* Space between checkbox and day name */
    vertical-align: middle;
    /* Ensures checkbox aligns with text */
    width: min-content;
  }

  .exportToExcelBtn {
    position: absolute;
    top: 14%;
    /* Distance from the top of the container */
    right: 5%;
    /* Distance from the right of the container */
    padding: 10px 20px;
    background-color: #1a2a6c;
    /* Button color */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}