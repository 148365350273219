/* Overlay (backdrop) that dims the screen */
.merge-dialog-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black overlay */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999; /* Make sure it appears above other elements */
  }
  
  /* Dialog Container */
  .merge-dialog-content {
    background-color: #fff;
    width: 80%;
    max-width: 600px;
    border-radius: 4px;
    padding: 20px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.3);
    position: relative;
  }
  
  /* Title */
  .merge-dialog-content h2 {
    margin: 0 0 1rem 0;
    font-size: 1.25rem;
  }
  
  /* Fields Wrapper */
  .merge-fields {
    max-height: 400px; /* scroll if too large */
    overflow-y: auto;
    margin: 1rem 0;
    padding-right: 0.5rem;
  }
  
  /* Single field row */
  .merge-field-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  
  /* Field label to the left */
  .merge-field-label {
    font-weight: bold;
    margin-bottom: 0.25rem;
  }
  
  /* Options (radio buttons) */
  .merge-field-options {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .merge-field-option {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .merge-field-option input[type='radio'] {
    margin-right: 0.25rem;
  }
  
  /* Button row */
  .merge-dialog-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem;
    gap: 1rem;
  }
  
  .merge-dialog-actions button {
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .merge-dialog-actions button:hover {
    opacity: 0.9;
  }
  
  /* Example button styling */
  .merge-dialog-actions button:first-child {
    background-color: #28a745; /* Save button (green) */
    color: #fff;
  }
  
  .merge-dialog-actions button:last-child {
    background-color: #dc3545; /* Cancel button (red) */
    color: #fff;
  }
  